import React, {useContext, useEffect, useState} from 'react';
import OrderContext from "@contexts/Order/OrderContext";
import Step from "@antd/Step";
import {ORDER_STATUS_STEP_LABELS, ORDER_STATUS_STEPS} from "@utils/constants/order.config";
import {PulseLoader} from "react-spinners";
import {Button, Icons, Tag, Tooltip} from "@dv/react";
import ArasKargoLogo from "@assets/images/aras-kargo-logo.png";
import MngKargoLogo from "@assets/images/mng-kargo-logo.png";
import {OWNER_TYPES} from "@utils/constants/order.config";
import moment from "moment/moment";
import {AWS_IMAGE_BUCKETS} from "@utils/BucketUploader";
import Upload from "@antd/Upload";
import {ENVIRONMENT_TAG} from "@utils/axiosInstances";
import PartialCancelModal from "@organisms/Modal/PartialCancelModal";
import ApproveOrderModal from "@organisms/Modal/ApproveOrderModal";
import CancelOrderModal from "@organisms/Modal/CancelOrderModal";
import NewOrderCargoBoxesModal from "@organisms/Modal/NewOrderCargoBoxesModal";

interface OrderProps {
    orderId?: any;
    detailToggle?: any;
    onConfirm?: any;
    onCancel?: any;
    styles: any;
}

const CargoLogo = ({carrierCode}) => {
    if (carrierCode === 'ARAS') {
        return <img src={ArasKargoLogo} height={25} width={50}/>
    } else if (carrierCode === 'MNG') {
        return <img src={MngKargoLogo} height={50} width={50}/>
    } else {
        return <Icons.TruckIcon color={"var(--primary-gray)"}/>;
    }
}


const OrderDetail: React.FC<OrderProps> = ({orderId, detailToggle, onConfirm, styles}) => {
    const [detail, setDetail] = useState(null);
    const [invoice, setInvoice] = useState(undefined);

    const [modals, setModals] = useState({
        approveOrderModal: false,
        declineOrderModal: false,
        partialCancelModal: false,
        newOrderCargoBoxesModal: false
    });

    const updateModal = (key, value) => {
        setModals({
            ...modals,
            [key]: value
        })
    }

    const orderContext = useContext(OrderContext);

    useEffect(() => {
        if (detailToggle === false) {
            orderContext.getOrderDetail(orderId, (orderDetail) => {
                setDetail(orderDetail);
            });
        }
    }, [detailToggle]);

    useEffect(() => {
        if (invoice) {
            orderContext.addOrderInvoice(orderId, invoice, (orderDetail) => {
                setDetail(orderDetail);
            });
        }
    }, [invoice]);

    // const cancelItem = (orderStatusId, orderItemId) => {
    //     console.log(orderStatusId, orderItemId);
    //     orderContext.submitForCancelApproval(orderStatusId, orderItemId, () => {
    //         orderContext.getOrderDetail(orderId, (orderDetail) => {
    //             setDetail(orderDetail);
    //         });
    //     })
    // }

    return orderContext.state.selectedOrder !== orderId || orderContext.state.detailLoading === false ? <>
        <div className={`${styles.step} ${styles.order_detail_steps}`}>
            <Step
                items={ORDER_STATUS_STEP_LABELS}
                current={ORDER_STATUS_STEPS[detail?.status]} direction={"horizontal"}/>
        </div>
        <div className={styles.order_detail}>
            <div className={styles.order_block}>
                <div className={styles.container}>
                    <div className={styles.container_block}>
                        <div className={styles.items}>
                            <div className={styles.order_items_table}>
                                <table>
                                    <tr>
                                        <th>Ürün Adı</th>
                                        <th style={{minWidth: 75}} className={styles.right_align}>Adet</th>
                                        <th className={styles.right_align}>Birim Fiyat</th>
                                        <th className={styles.right_align}>Kampanya Tutarı</th>
                                        <th className={styles.right_align}>DP İskonto & Yüzdesi</th>
                                        <th className={styles.right_align}>Fatura Kesilecek Tutar (KDV Dahil)</th>
                                    </tr>
                                    {
                                        detail?.items?.map((item, i) => (
                                            <tr key={i}>
                                                <td className={"flex ai-center border-bottom-none"}>
                                                    <div className={"flex fd-column"}>
                                                        <div className={"flex ai-center"}>
                                                            <div className={"pie-10"}>
                                                                <img alt={`${item?.id}_product_image`}
                                                                     height={75} width={75}
                                                                     src={item?.thumbnail}/>
                                                            </div>
                                                            <div className={"flex fd-column"}>
                                                                <span className={"pbe-5"}
                                                                      style={{fontSize: 14}}>{item.name}
                                                                    <b>{item.variantTitle}</b></span>
                                                                {item.expiration &&
                                                                    <span
                                                                        style={{fontSize: 11}}><b>Miat:</b> {moment(item.expiration).format('DD MMMM YYYY')}</span>
                                                                }
                                                                <span
                                                                    style={{fontSize: 11}}><b>Marka:</b> {item.brandName}</span>
                                                                <span
                                                                    style={{fontSize: 11}}><b>Ambalaj İçeriği:</b> {item.packageContent}</span>
                                                                <span
                                                                    style={{fontSize: 11}}><b>Ürün Kodu:</b> {item.sku}</span>
                                                                <span
                                                                    style={{fontSize: 11}}><b>Katalog No:</b> {item.catalogNumber}</span>
                                                                {
                                                                    item?.options && item?.options?.map((option, i) => (
                                                                        <span key={i}
                                                                              style={{fontSize: 11}}><b>{option?.propertyName}:</b> {option?.propertyValueName}</span>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td className={"flex gap-5 border-top-none"}>

                                                    {(item?.hasCampaign && item?.isCampaignApplied && item?.isGift !== 1) &&
                                                        <Tag variant={"campaign"} campaign={item?.campaign}/>}

                                                    {item?.isGift === 1 && <Tag variant={"gift"}/>}

                                                    {(item?.submittedForCancelApproval === 1 && item?.isCancelApproved === null) &&
                                                        <div className={"tags"}>
                                                            <div
                                                                className={"tag tag-m blue_secondary ta-center"}>
                                                                <span>İptal Onayı Bekliyor</span>
                                                            </div>
                                                        </div>
                                                    }

                                                    {item?.isCancelApproved !== null &&
                                                        <>
                                                            {item?.isCancelApproved === 1 &&
                                                                <div className={"tags"}>
                                                                    <div
                                                                        className={"tag tag-m green_secondary ta-center"}>
                                                                        <span>İptal Talebi Onaylandı</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {item?.isCancelApproved === 0 &&
                                                                <div className={"tags"}>
                                                                    <div
                                                                        className={"tag tag-m red_secondary ta-center"}>
                                                                        <span>İptal Talebi Reddedildi</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    }

                                                    {item?.isCanceled === 1 &&
                                                        <div className={"tags"}>
                                                            <div
                                                                className={"tag tag-m green_secondary ta-center"}>
                                                                {item?.canceledAmount ?
                                                                    <span>{item?.canceledAmount} Adet Ürün İptal Edildi</span> :
                                                                    <span>Ürün İptal Edildi</span>}
                                                            </div>
                                                        </div>
                                                    }

                                                    {item?.isRefund === 1 && item?.isIyzicoRefund === 0 &&
                                                        <div className={"tags"}>
                                                            <div
                                                                className={"tag tag-m orange_secondary ta-center"}>
                                                                <span>İade İstendi</span>
                                                            </div>
                                                        </div>
                                                    }

                                                    {item?.isRefund === 1 && item?.isIyzicoRefund === 1 &&
                                                        <div className={"tags"}>
                                                            <div
                                                                className={"tag tag-m red_secondary ta-center"}>
                                                                <span>İade Edildi</span>
                                                            </div>
                                                        </div>
                                                    }

                                                    {item?.isExchange === 1 && item?.isExchanged === null &&
                                                        <div className={"tags"}>
                                                            <div
                                                                className={"tag tag-m orange_secondary ta-center"}>
                                                                <span>Değişim İstendi</span>
                                                            </div>
                                                        </div>
                                                    }

                                                    {item?.isExchange === 1 && item?.isExchanged === 1 &&
                                                        <div className={"tags"}>
                                                            <div
                                                                className={"tag tag-m red_secondary ta-center"}>
                                                                <span>Değişim Yapıldı</span>
                                                            </div>
                                                        </div>
                                                    }

                                                </td>

                                                <td style={{
                                                    minWidth: 75,
                                                    color: 'var(--primary-red)',
                                                    fontWeight: 'bold'
                                                }} className={styles.right_align}>
                                                    {item?.isCancelApproved ?
                                                        <>
                                                            <s>{item?.amount} Adet</s>
                                                            <br/>
                                                            {item?.amount - item?.canceledAmount} Adet
                                                        </>
                                                        :
                                                        <>{item.amount} Adet</>
                                                    }
                                                </td>

                                                <td className={styles.right_align}>
                                                    {item?.isGift === 0 ? <>{item?.amountPrice?.toFixed(2)}{item?.currency?.symbol || '₺'}</>
                                                        : 'HEDİYE'}
                                                </td>

                                                <td className={styles.right_align}>
                                                    {item?.isCampaignApplied ?
                                                        (item?.isCancelApproved ?
                                                                <>-{(
                                                                    (item?.campaignAppliedPrice / item?.amount) * (item?.amount - item?.canceledAmount)
                                                                )?.toFixed(2)}{item?.currency?.symbol || '₺'}</>
                                                                :
                                                                <>-{item?.campaignAppliedPrice?.toFixed(2)}{item?.currency?.symbol || '₺'}</>
                                                        )
                                                        : '-'}
                                                </td>

                                                <td className={"ta-center"}>
                                                    {
                                                        item?.discountPrice > 0 ?
                                                            (item?.isCancelApproved ?
                                                                <span
                                                                    className={"flex ai-center w-100 ta-center jc-center"}>-{(
                                                                    (item?.discountPrice / item?.amount) * (item?.amount - item?.canceledAmount)
                                                                )?.toFixed(2)}{item?.currency?.symbol || '₺'}</span>
                                                                :
                                                                <span
                                                                    className={"flex ai-center w-100 ta-center jc-center"}>-{item?.discountPrice?.toFixed(2)}{item?.currency?.symbol || '₺'}</span>)
                                                            : '-'
                                                    }

                                                    {item?.discountPrice > 0 ? <>
                                                        <div className={"flex ai-center w-100 ta-center jc-center"}>
                                                            %{(item?.discountPercent * 100)?.toFixed(2)}
                                                        </div>
                                                    </> : ''}
                                                </td>

                                                <td className={styles.right_align}>
                                                    {(item?.isCancelApproved && (item?.canceledAmount && item?.canceledPrice)) ?
                                                        <>
                                                            <s>{(item.iyzico?.price || item?.payment?.price)?.toFixed(2)}₺</s>
                                                            <br/>
                                                            {(
                                                                (item.iyzico?.paidPrice || item?.payment?.paidPrice) - item?.canceledPrice +
                                                                ((item?.iyzico?.installmentPrice || item?.payment?.installmentPrice) ? (((item?.iyzico?.installmentPrice || item?.payment?.installmentPrice) / item?.amount) * (item?.remainingAmount)) : 0)
                                                            )?.toFixed(2)}₺
                                                        </>
                                                        :
                                                        <>{(item?.iyzico?.price || item?.payment?.price)?.toFixed(2)}₺</>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </table>

                                {/*<p><b>Not:</b> Parçalı iptallerde müşterinin ödediğin tutarın iptal edilen adet sayısına oranla hesaplanarak ücret iadesi yapılmaktadır.</p>*/}
                            </div>
                            {/*
                                detail?.items?.map(item => (
                                    <div key={item.listingId}
                                         className={`${styles.item} ${item?.isRefund === 1 && styles.refunded}`}>
                                        <div className={styles.image}>
                                            <img alt={`${item?.id}_product_image`}
                                                 src={item?.thumbnail}/>
                                            {(item?.submittedForCancelApproval === 1 && item?.isCancelApproved === null) &&
                                                <div className={"tags"}>
                                                    <div
                                                        className={"tag tag-m blue_secondary max-w-100 ws-wrap ta-center"}>
                                                        <span>İptal Onayı Bekliyor</span>
                                                    </div>
                                                </div>
                                            }
                                            {item?.isCancelApproved !== null &&
                                                <>
                                                    {item?.isCancelApproved === 1 &&
                                                        <div className={"tags"}>
                                                            <div
                                                                className={"tag tag-m green_secondary max-w-100 ws-wrap ta-center"}>
                                                                <span>İptal Talebi Onaylandı</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    {item?.isCancelApproved === 0 &&
                                                        <div className={"tags"}>
                                                            <div
                                                                className={"tag tag-m red_secondary max-w-100 ws-wrap ta-center"}>
                                                                <span>İptal Talebi Reddedildi</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                            {item?.isCanceled === 1 &&
                                                <div className={"tags"}>
                                                    <div
                                                        className={"tag tag-m green_secondary max-w-100 ws-wrap ta-center"}>
                                                        <span>Ürün İptal Edildi</span>
                                                    </div>
                                                </div>
                                            }
                                            {item?.isRefund === 1 &&
                                                <div className={"tags"}>
                                                    <div
                                                        className={"tag tag-m red_secondary max-w-100 ws-wrap ta-center"}>
                                                        <span>İade Edildi</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={styles.item_detail}>


                                            <div className={styles.block}>
                                                <div className={styles.name}>
                                                    <b>{item?.name} {item?.variantTitle}</b>
                                                </div>
                                                {item?.isCancelApproved ?
                                                    <div className={styles.order_font_size}>
                                                        <s>Adet: {item?.amount}</s>
                                                        <br/>
                                                        Adet: {item?.amount - item?.canceledAmount}
                                                    </div>
                                                    :
                                                    <div className={styles.order_font_size}>
                                                        Adet: {item?.amount}
                                                    </div>
                                                }
                                            </div>


                                            <div className={styles.block}>
                                                <div className={styles.title}>
                                                    MARKA
                                                </div>
                                                <div className={`${styles.description} flex ai-center gap-10`}>
                                                    <img alt={`brand-${item?.brandName}`} src={item?.brandLogo}
                                                         height={35} width={40}/>
                                                    {item?.brandName}
                                                </div>
                                            </div>

                                            <div className={styles.block}>
                                                <div className={styles.title}>
                                                    ÜRÜN KODU
                                                </div>
                                                <div className={styles.description}>
                                                    {item?.sku}
                                                </div>
                                            </div>

                                            <div className={styles.block}>
                                                <div className={styles.title}>
                                                    KATALOG NUMARASI
                                                </div>
                                                <div className={styles.description}>
                                                    {item?.catalogNumber}
                                                </div>
                                            </div>

                                            <div className={styles.block}>
                                                <div className={styles.title}>
                                                    AMBALAJ İÇERİĞİ
                                                </div>
                                                <div className={styles.description}>
                                                    {item?.packageContent}
                                                </div>
                                            </div>

                                            {
                                                item?.options && item?.options?.map((option, i) => (
                                                    <div key={i} className={styles.block}>
                                                        <div className={styles.title}>
                                                            {option?.propertyName}
                                                        </div>
                                                        <div className={styles.description}>
                                                            {option?.propertyValueName}
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                            {item?.hasCampaign && item?.isCampaignApplied &&
                                                <div className={styles.block}>
                                                    <div className={styles.title}>
                                                        KAMPANYALI ÜRÜN
                                                    </div>
                                                    <div className={`${styles.description} ${styles.orange}`}>
                                                        <Tag variant={"campaign"} campaign={item?.campaign}/>
                                                    </div>
                                                </div>
                                            }

                                            {item?.isGift === 1 &&
                                                <div className={styles.block}>
                                                    <div className={styles.title}>
                                                        KAMPANYALI ÜRÜN
                                                    </div>
                                                    <div className={`${styles.description} ${styles.orange}`}>
                                                        <Tag variant={"gift"}/>
                                                    </div>
                                                </div>
                                            }


                                            <div className={styles.block}>
                                                <div className={styles.title}>
                                                    SATIŞ FİYATI
                                                </div>
                                                <div className={`${styles.description} ${styles.orange}`}>
                                                    {item?.isGift === 0 ? <>{item?.amountPrice?.toFixed(2)} {item?.currency?.symbol || '₺'}</>
                                                        : 'HEDİYE'}

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ))
                            */}
                        </div>
                    </div>
                </div>
                <div className={styles.summary_block}>
                    <div className={styles.cargo}>
                        {detail?.status === 510 && <div className={styles.cargo_detail_block}>
                            <div className={styles.icon}>
                                <Icons.UserIcon color={"var(--primary-gray)"}/>
                            </div>
                            <div className={styles.cargo_detail}>
                                <div className={styles.block}>
                                    <div className={styles.title}>
                                        TİCARİ ÜNVANI
                                    </div>
                                    <div className={styles.description}>
                                        {(detail?.trackingAddress?.invoiceCommercialTitle || detail?.trackingAddress?.receiverName)?.toLocaleUpperCase('TR')}
                                    </div>
                                </div>
                                <div className={styles.block}>
                                    <div className={styles.title}>
                                        HEKİM
                                    </div>
                                    <div className={styles.description}>
                                        {detail?.trackingAddress?.name}
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {detail?.status <= 250 ?
                            <div className={`${styles.cargo_title} ${styles.align_center}`}>
                                {detail?.trackingInfo?.carrierCode &&
                                    <div className={styles.icon}>
                                        <CargoLogo carrierCode={detail?.trackingInfo?.carrierCode}/>
                                    </div>
                                }
                                <div className={styles.detail}>
                                    <p><b>{detail?.trackingInfo?.carrierName}</b>
                                    </p>
                                </div>
                            </div>
                            :
                            <div className={styles.cargo_title}>
                                <div className={styles.icon}>
                                    <CargoLogo carrierCode={detail?.trackingInfo?.carrierCode}/>
                                </div>
                                <div className={styles.detail}>
                                    <p><b>{detail?.trackingInfo?.integrationStatusName || '-'}</b></p>
                                    <br/>
                                    <p><b>{detail?.trackingInfo?.carrierName}</b>
                                    </p>
                                    <p>Gönderi Kodu: <b>{detail?.trackingInfo?.shipmentId}</b></p>
                                    <br/>
                                    <p><b>Çıkış:</b> {detail?.trackingInfo?.cargoOutBranch}</p>
                                    <p>{detail?.trackingInfo?.cargoOutAt ?
                                        <p>{moment(detail?.trackingInfo?.cargoOutAt, 'DD-MM-YYYY HH:mm:ss')
                                            .format('DD.MM.YY, HH:mm')}</p> : "-"}</p>
                                    <br/>
                                    <p><b>Varış:</b> {detail?.trackingInfo?.cargoInBranch}</p>
                                    <p>{detail?.trackingInfo?.cargoInAt ?
                                        <p>{moment(detail?.trackingInfo?.cargoInAt, 'DD-MM-YYYY HH:mm:ss')
                                            .format('DD.MM.YY, HH:mm')}</p> : "-"}</p>
                                    {detail?.status === 500 && <>
                                        <br/>
                                        <p><b>Teslim Alan:</b> {detail?.trackingInfo?.receivedName}</p>
                                        <p><b>Teslimat Tarihi:</b> {detail?.trackingInfo?.receivedAt ?
                                            <p>{moment(detail?.trackingInfo?.receivedAt, 'YYYY-MM-DDTHH:mm:ssZ')
                                                .format('DD.MM.YY, HH:mm')}</p> : "-"}</p>
                                    </>}
                                </div>
                            </div>
                        }
                        {detail?.status < 200 ?
                            <Tag label={"Siparişi onayladığınız süreçte bilgilere ulaşabilirsiniz."}
                                 variant={"warning"}/>
                            :
                            detail?.status < 300 &&
                            <div className={styles.cargo_detail_block}>
                                <div className={styles.icon}>
                                    <Icons.DeliveryBoxIcon color={"var(--primary-gray)"}/>
                                </div>
                                <div className={styles.cargo_detail}>
                                    <div className={styles.block}>
                                        <div className={styles.title}>
                                            TİCARİ ÜNVANI
                                        </div>
                                        <div className={styles.description}>
                                            {(detail?.trackingAddress?.invoiceCommercialTitle || detail?.trackingAddress?.receiverName)?.toLocaleUpperCase('TR')}
                                        </div>
                                    </div>
                                    {/*
                                    <div className={styles.block}>
                                    <div className={styles.title}>
                                    ALICI ÜNVANI
                                    </div>
                                    <div className={styles.description}>
                                {detail?.trackingAddress?.name}
                                    </div>
                                    </div>
                                */}
                                    {/*
                              <div className={styles.block}>
                                <div className={styles.title}>
                                  E-POSTA ADRESİ
                                </div>
                                <div className={styles.description}>
                                    {detail?.trackingAddress?.email}
                                </div>
                              </div>
                                */}
                                    {/*
                                    <div className={styles.block}>
                                        <div className={styles.title}>
                                            TESLİMAT ADRESİ
                                        </div>
                                        <div className={styles.description}>
                                            {detail?.trackingAddress?.shippingAddress}
                                        </div>
                                    </div>
                                */}
                                    <div className={styles.block}>
                                        <div className={styles.title}>
                                            FATURA ADRESİ
                                        </div>
                                        <div className={styles.description}>
                                            {(detail?.trackingAddress?.invoiceAddress)?.toLocaleUpperCase('TR')}
                                        </div>
                                    </div>
                                    <div className={styles.block}>
                                        <div className={styles.title}>
                                            VERGİ DAİRESİ
                                        </div>
                                        <div className={styles.description}>
                                            {(detail?.trackingAddress?.invoiceTaxOffice)?.toLocaleUpperCase('TR')}
                                        </div>
                                    </div>
                                    <div className={styles.block}>
                                        <div className={styles.title}>
                                            T.C. / VERGİ NO
                                        </div>
                                        <div className={styles.description}>
                                            {detail?.trackingAddress?.invoiceTaxNumber || detail?.trackingAddress?.invoiceTckn}
                                        </div>
                                    </div>

                                    <div className={styles.block}>
                                        <div className={styles.title}>
                                            FATURA
                                        </div>
                                        <div className={styles.description}>
                                            {detail?.invoiceFile === null ?
                                                <Upload
                                                    tooltip={"Fatura ekleyiniz"}
                                                    bucketOptions={{
                                                        bucketName: AWS_IMAGE_BUCKETS.NAMES.DOCUMENT.name,
                                                        filePath: AWS_IMAGE_BUCKETS.NAMES.DOCUMENT.PATHS.vendorInvoices
                                                    }}
                                                    accept={"image/*, .doc, .docx,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf"}
                                                    url={invoice}
                                                    setUrl={(url) => {
                                                        setInvoice(url);
                                                    }}/>
                                                :
                                                <div className={"d-flex fd-column"}>
                                                    <a target={"_blank"} href={detail?.invoiceFile} rel="noreferrer">Fatura
                                                        için
                                                        tıklayınız</a>
                                                    <Upload
                                                        tooltip={"Fatura değiştir"}
                                                        bucketOptions={{
                                                            bucketName: AWS_IMAGE_BUCKETS.NAMES.DOCUMENT.name,
                                                            filePath: AWS_IMAGE_BUCKETS.NAMES.DOCUMENT.PATHS.vendorInvoices
                                                        }}
                                                        accept={"image/*, .doc, .docx,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf"}
                                                        url={undefined}
                                                        setUrl={(url) => {
                                                            setInvoice(url);
                                                        }}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {/*(detail?.totalLostSalePrice && detail?.totalLostEarnedPrice) &&
                      <div className={styles.summary}>
                        <div className={styles.block}>
                          <div className={`${styles.title} flex ai-center gap-5`}>
                            KAYBEDİLEN SATIŞ TUTARI
                            <Tooltip
                              title={"İptal ettiğiniz ürünler sonucunda kaybedilen tutar"}>
                              <Icons.InfoIcon/>
                            </Tooltip>
                          </div>
                          <div className={styles.value}>
                              {detail?.accounting?.totalLostSalePrice?.toFixed(2)} ₺
                          </div>
                        </div>
                        <div className={styles.block} style={{
                            border: '.5px solid var(--primary-red)',
                            borderRadius: '6px',
                            paddingBlock: '6px',
                            borderBottomRightRadius: 0,
                            borderTopRightRadius: 0,
                            backgroundColor: 'var(--secondary-red)'
                        }}>
                          <div className={styles.title} style={{color: 'var(--primary-red)'}}>
                            KAYBEDİLEN HAKEDİŞ TUTARI
                          </div>
                          <div className={styles.value} style={{color: 'var(--primary-red)'}}>
                              {detail?.accounting?.totalLostEarnedPrice?.toFixed(2)} ₺
                          </div>
                        </div>
                      </div>
                    */}
                    <div className={styles.summary}>

                        <div className={styles.block}>
                            <div className={styles.title}>
                                TOPLAM SATIŞ TUTARI
                            </div>
                            <div className={styles.value}>
                                {/*((detail?.isCargoFree === 1 ? (detail?.totalPrice - detail?.totalLostSalePrice) : (detail?.totalPrice - (detail?.cargoPrice + detail?.totalLostSalePrice))))?.toFixed(2)*/}
                                {/*(detail?.isCargoFree === 1 ? (detail?.totalPrice) : (detail?.totalPrice - detail?.cargoPrice))?.toFixed(2)*/}
                                {detail?.accounting?.totalSalePriceNoCarryingCharge?.toFixed(2)} ₺
                                {/*detail?.accounting?.hasCarryingCharge && `+ ${detail?.accounting?.carryingCharge?.toFixed(2)}`*/}
                            </div>
                        </div>

                        {(detail?.totalLostSalePrice && detail?.totalLostEarnedPrice) &&
                            <div className={styles.block} style={{
                                border: '.5px solid var(--primary-red)',
                                borderRadius: '6px',
                                paddingBlock: '6px',
                                borderBottomRightRadius: 0,
                                borderTopRightRadius: 0,
                                backgroundColor: 'var(--secondary-red)'
                            }}>
                                <div className={`${styles.title} flex ai-center gap-5`}
                                     style={{color: 'var(--primary-red)'}}>
                                    KAYBEDİLEN SATIŞ TUTARI
                                    <Tooltip
                                        title={"İptal edilen ürünler sonucunda kaybedilen satış tutarı"}>
                                        <Icons.InfoIcon/>
                                    </Tooltip>
                                </div>
                                <div className={styles.value} style={{color: 'var(--primary-red)'}}>
                                    -{detail?.accounting?.totalLostSalePrice?.toFixed(2)} ₺
                                </div>
                            </div>
                        }
                        <div className={styles.block}>
                            <div className={styles.title}>
                                KAMPANYA
                            </div>
                            <div className={styles.value}>
                                {/*(detail?.campaignAppliedPrice * -1)?.toFixed(2)*/}
                                {/*{(row?.commissionPrice + row?.iyzicoCommissionPrice).toFixed(2)} ₺*/}
                                -{detail?.accounting?.totalCampaignAppliedPrice?.toFixed(2)} ₺
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={`${styles.title} flex ai-center gap-5`}>
                                DP İSKONTO
                                {/*detail?.discountCode && `(${detail?.discountType === 0 ? '%' : ''} ${detail?.discountValue}${detail?.discountType === 1 ? 'TL' : ''})`*/}

                                <Tooltip
                                    title={"Bu iskonto tutarı, Dentalpiyasa tarafından karşılanmaktadır. İlk ödeme gününde hesabınıza aktarılacaktır."}>
                                    <Icons.InfoIcon/>
                                </Tooltip>

                            </div>
                            <div className={styles.value}>
                                {/*(detail?.discountPrice * -1)?.toFixed(2)*/}
                                -{detail?.accounting?.totalDpDiscountPrice?.toFixed(2)} ₺
                            </div>
                        </div>
                        <div className={styles.block} style={{
                            border: '.5px solid var(--primary-orange)',
                            borderRadius: '6px',
                            paddingBlock: '6px',
                            borderBottomRightRadius: 0,
                            borderTopRightRadius: 0,
                            backgroundColor: 'var(--secondary-orange)'
                        }}>
                            <div className={styles.title} style={{color: 'var(--primary-orange)'}}>
                                FATURA KESİLECEK TUTAR (KDV DAHİL)
                            </div>
                            <div className={styles.value} style={{color: 'var(--primary-orange)'}}>
                                {detail?.accounting?.amountToBeInvoiced?.toFixed(2)} ₺
                            </div>
                        </div>

                        {
                            <div className={styles.block}>
                                <div className={`${styles.title} flex ai-center gap-5`}>
                                    TAHMİNİ KARGO ÜCRETİ
                                    <Tooltip
                                        title={
                                            <>
                                                Kargo desileri tahminidir. Kargo firması tarafından nihai desi
                                                miktarları
                                                netleştirildiğinde, tarafınız ile mahsuplaşma yapılacaktır. Dentalpiyasa
                                                desi
                                                başına kargo ücretini öğrenmek için <span style={{
                                                color: 'var(--primary-orange)',
                                                cursor: 'pointer'
                                            }}
                                                                                          onClick={() => orderContext.setCargoDesiPriceModalToggle(true)}>tıklayınız</span>
                                            </>
                                        }>
                                        <Icons.InfoIcon/>
                                    </Tooltip>
                                </div>
                                <div className={styles.value}>
                                    {detail?.payer &&
                                        ((detail?.payer === 200) ? `${detail?.cargoPrice * -1} ₺`
                                            :
                                            OWNER_TYPES[detail?.payer] ? `${OWNER_TYPES[detail?.payer]} Ödedi` : detail?.isCargoFree === 1 ? `${(detail?.cargoPrice * -1)?.toFixed(2)} ₺` : 'HEKİM ÖDEDİ')}
                                </div>
                            </div>
                        }

                        <div className={styles.block}>
                            <div className={`${styles.title} flex ai-center gap-5`}>
                                TAHMİNİ TOPLAM DESİ
                            </div>
                            <div className={styles.value}>
                                {detail?.totalDesi?.toFixed(2)}
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.title}>
                                KOMİSYON
                            </div>
                            <div className={styles.value}>
                                {/*(detail?.commissionPrice * -1)?.toFixed(2)*/}
                                {/*{(row?.commissionPrice + row?.iyzicoCommissionPrice).toFixed(2)} ₺*/}
                                -{(detail?.accounting?.totalCommissionPrice)?.toFixed(2)} ₺
                            </div>
                        </div>
                        {/*detail?.totalLostEarnedPrice &&
                          <div className={styles.block}>
                            <div className={styles.title}>
                              KAYBEDİLEN HAKEDİŞ TUTARI
                            </div>
                            <div className={styles.value}>
                              -{(detail?.totalLostEarnedPrice)?.toFixed(2)} ₺
                            </div>
                          </div>
                        */}
                        <div className={styles.block}
                             style={{borderBottom: '.5px solid var(--primary-gray)', paddingBlockEnd: '6px'}}>
                            <div className={styles.title}>
                                ARA TOPLAM
                            </div>
                            <div className={styles.value}>
                                {/*((detail?.totalPrice - detail?.totalLostSalePrice) - (((detail?.payer === 300 ? 0 : detail?.cargoPrice) + (detail?.discountPrice + detail?.commissionPrice + detail?.campaignAppliedPrice))))?.toFixed(2)*/}
                                {/*(detail?.accounting?.totalSalePrice - (detail?.accounting?.totalLostEarnedPrice + detail?.accounting?.totalCommissionPrice))?.toFixed(2)*/}
                                {detail?.accounting?.totalDepositFromPayment?.toFixed(2)} ₺
                            </div>
                        </div>

                        {(detail?.totalLostSalePrice && detail?.totalLostEarnedPrice) &&
                            <div className={styles.block} style={{
                                border: '.5px solid var(--primary-red)',
                                borderRadius: '6px',
                                paddingBlock: '6px',
                                borderBottomRightRadius: 0,
                                borderTopRightRadius: 0,
                                backgroundColor: 'var(--secondary-red)'
                            }}>
                                <div className={`${styles.title} flex ai-center gap-5`}
                                     style={{color: 'var(--primary-red)'}}>
                                    KAYBEDİLEN HAKEDİŞ TUTARI
                                    <Tooltip
                                        title={"İptal edilen ürünler sonucunda kaybedilen hakediş tutarı"}>
                                        <Icons.InfoIcon/>
                                    </Tooltip>
                                </div>
                                <div className={styles.value} style={{color: 'var(--primary-red)'}}>
                                    -{detail?.accounting?.totalLostEarnedPrice?.toFixed(2)} ₺
                                </div>
                            </div>
                        }
                        {/*<div className={styles.block}>*/}
                        {/*    <div className={styles.title} style={{paddingBlockStart: '6px'}}>*/}
                        {/*        IYZICO'DAN YATACAK TUTAR*/}
                        {/*    </div>*/}
                        {/*    <div className={styles.value}>*/}
                        {/*        /!*((detail?.totalPrice - detail?.totalLostSalePrice) - (((detail?.payer === 300 ? 0 : detail?.cargoPrice) + (detail?.discountPrice + detail?.commissionPrice + detail?.campaignAppliedPrice))))?.toFixed(2)*!/*/}
                        {/*        {detail?.accounting?.totalDepositFromPayment?.toFixed(2)} ₺*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={styles.block}>*/}
                        {/*    <div className={styles.title}>*/}
                        {/*        DENTALPİYASA'DAN YATACAK TUTAR*/}
                        {/*    </div>*/}
                        {/*    <div className={styles.value}>*/}
                        {/*        /!*detail?.discountPrice?.toFixed(2)*!/*/}
                        {/*        /!*{(row?.totalPrice - (row?.commissionPrice + row?.iyzicoCommissionPrice)).toFixed(2)} ₺*!/*/}
                        {/*        {detail?.accounting?.totalDepositFromDp?.toFixed(2)} ₺*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className={styles.block}
                             style={{
                                 border: '.5px solid var(--secondary-gray)',
                                 borderRadius: '6px',
                                 paddingBlock: '6px',
                                 borderBottomRightRadius: 0,
                                 borderTopRightRadius: 0,
                                 backgroundColor: 'var(--primary-gray)'
                             }}>
                            <div className={styles.title} style={{color: 'var(--secondary-gray)'}}>
                                NET DEPO TUTARI
                            </div>
                            <div className={styles.value} style={{color: 'var(--secondary-gray)'}}>
                                {/*((detail?.totalPrice - detail?.totalLostSalePrice) - (((detail?.payer === 300 ? 0 : detail?.cargoPrice) + detail?.commissionPrice + detail?.campaignAppliedPrice)))?.toFixed(2)*/}
                                {/*{(row?.totalPrice - (row?.commissionPrice + row?.iyzicoCommissionPrice)).toFixed(2)} ₺*/}
                                {(detail?.accounting?.totalDepositFromPayment + detail?.accounting?.totalDepositFromDp)?.toFixed(2)} ₺
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.actions_block}>
                {(detail?.status < 200) &&
                    <>
                        {/*{detail?.items?.find(el => el.isCanceled === 0 || el.isCanceled === null) &&*/}
                        {detail?.remainingItemsAmount > 0 &&
                            <>
                                {detail?.items?.find(el => el.submittedForCancelApproval === 1 && el.isCancelApproved === null) ?
                                    <>
                                        <Tooltip
                                            title={"Bu sipariş özelinde iptal onayı bekleyen bir ürününüz mevcut olduğundan dolayı sipariş/ürün iptali tekrarlanamaz."}>
                                            <Button variant={"compact outlined"} disabled={true}>İptal Süreci Devam
                                                Ediyor</Button>
                                        </Tooltip>
                                        <Tooltip
                                            title={"Bu sipariş özelinde iptal onayı bekleyen bir ürününüz mevcut olduğundan dolayı siparişi onaylayamazsınız."}>
                                            <Button variant={"compact outlined-primary"} disabled={true}>Sipariş
                                                Onaylanamaz</Button>
                                        </Tooltip>
                                    </>
                                    :
                                    <>
                                        <Button order-status-id={detail?.orderStatusId} variant={"compact outlined"}
                                                onClick={() => {
                                                    // const selectedId = e.target.getAttribute('order-status-id');
                                                    // onCancel(selectedId);
                                                    updateModal('declineOrderModal', true);
                                                }} disabled={orderContext.state.loading}>Siparişi İptal Et</Button>
                                        <Button order-id={detail?.id} variant={"compact outlined-primary"}
                                                onClick={() => {
                                                    // const selectedId = e.target.getAttribute('order-id');
                                                    // onConfirm(selectedId);
                                                    updateModal('approveOrderModal', true);
                                                }} disabled={orderContext.state.loading}>Siparişi Onayla</Button>
                                    </>
                                }

                            </>
                        }
                    </>
                }

                {(detail?.status === 200) && (
                    detail?.invoiceFile === null ?
                        <Tooltip title={"Kargo öncesi fatura eklemeniz gerekir."} placement={"left"}>
                            <Button order-id={detail?.id} variant={"compact outlined-primary"} onClick={(e) => {
                                const selectedId = e.target.getAttribute('order-id');
                                onConfirm(selectedId);
                            }}
                                    disabled={orderContext.state.loading || (detail?.invoiceFile === null && detail?.skipInvoice === 0)}>Kargo
                                Görevlisini
                                Çağır</Button>
                        </Tooltip>
                        :
                        <Button order-id={detail?.id} variant={"compact outlined-primary"} onClick={(e) => {
                            const selectedId = e.target.getAttribute('order-id');
                            onConfirm(selectedId);
                        }}
                                disabled={orderContext.state.loading || (detail?.invoiceFile === null && detail?.skipInvoice === 0)}>Kargo
                            Görevlisini
                            Çağır</Button>
                )}


                {/*
                <Button order-id={detail?.id} variant={"compact outlined-primary"} onClick={(_e) => {
                    updateModal('newOrderCargoBoxesModal', true);
                }} disabled={orderContext.state.loading || (detail?.invoiceFile === null && detail?.skipInvoice === 0)}>
                    Kargo Kolilerini Oluştur</Button>
                */}


                {detail?.status === 250 &&
                    <>
                        <Button order-id={detail?.id} variant={"compact outlined-primary"} onClick={(e) => {
                            const selectedId = e.target.getAttribute('order-id');
                            window.open(`/orders/${selectedId}/cargo-print`, '_blank');
                        }} disabled={orderContext.state.loading}>Kargo Etiketi Yazdır</Button>
                        {ENVIRONMENT_TAG === 'DEV' &&
                            <>
                                <Button order-id={detail?.id} variant={"compact outlined-primary"} onClick={(e) => {
                                    const selectedId = e.target.getAttribute('order-id');
                                    if (onConfirm) {
                                        onConfirm(selectedId);
                                    }
                                }} disabled={orderContext.state.loading}>SÜREÇ İLERLET (TEST)</Button>
                            </>
                        }
                    </>
                }

                {(ENVIRONMENT_TAG === 'DEV' && (detail?.status >= 200 && detail?.status < 500)) &&
                    <>
                        <Button order-id={detail?.id} variant={"compact outlined-primary"} onClick={(e) => {
                            const selectedId = e.target.getAttribute('order-id');
                            if (onConfirm) {
                                onConfirm(selectedId);
                            }
                        }} disabled={orderContext.state.loading}>SÜREÇ İLERLET (TEST)</Button>
                        <Button order-id={detail?.id} variant={"compact outlined-primary"} onClick={(e) => {
                            const selectedId = e.target.getAttribute('order-id');
                            orderContext.setOrderStatus(selectedId, 500, () => {
                            });
                        }} disabled={orderContext.state.loading}>TESLİM EDİLDİ İLERLET (TEST)</Button>
                    </>
                }

                {detail?.status === 500 &&
                    <Button variant={"compact outlined-primary"}>Siparişi Yazdır</Button>
                }
            </div>

            <ApproveOrderModal title={"Yeni Sipariş Onayla"} show={modals.approveOrderModal}
                               setShow={(value) => updateModal('approveOrderModal', value)} selectedOrderId={orderId}/>

            <CancelOrderModal title={"Sipariş İptali"} show={modals.declineOrderModal} setModals={setModals}
                              setShow={(value) => updateModal('declineOrderModal', value)}
                              selectedOrderStatusId={detail?.orderStatusId}/>

            <PartialCancelModal title={"İptal etmek istediğiniz ürünleri seçiniz"} show={modals.partialCancelModal}
                                orderId={orderId} orderStatusId={detail?.orderStatusId} setDetail={setDetail}
                                setShow={(value) => updateModal('partialCancelModal', value)} products={detail?.items}/>

            <NewOrderCargoBoxesModal title={"Ürünlerinizi Kolileyin"} show={modals.newOrderCargoBoxesModal}
                                     orderId={orderId} orderStatusId={detail?.orderStatusId} setDetail={setDetail}
                                     setShow={(value) => updateModal('newOrderCargoBoxesModal', value)}
                                     productsData={detail?.items || []}/>


        </div>
    </> : orderContext.state.selectedOrder === orderId &&
        <PulseLoader size={20} margin={10} color="var(--primary-orange)"/>
}

export default OrderDetail;
