import React, {useContext, useEffect, useState} from "react";

import styles from '@dv/scss/lib/RefundOrders.module.scss';

import {Title, Icons, Input, Button, Tooltip} from '@dv/react/lib';

import Tabs from "@organisms/Tabs";
import TabPane from "@organisms/Tabs/TabPane";
import TabContent from "@organisms/Tabs/TabContent";
import Layout from "@organisms/Layout";
import CardTable from "@organisms/CardTable";
import RefundOrderContext from "@contexts/RefundOrder/RefundOrderContext";
import RangePicker from "@antd/RangePicker";
import {useSearchParams} from "react-router-dom";
import Modal from "@organisms/Modal/Modal";

const RefundOrders: React.FC<any> = () => {
    const [modals, setModals] = useState({
        approveModal: false,
        declineModal: false
    });

    const updateModal = (key, value) => {
        setModals({
            ...modals,
            [key]: value
        })
    }

    let [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        setCurrentTab(parseInt(searchParams.get('tab')) || 1);
    }, [searchParams.get('tab')]);

    const refundOrderContext = useContext(RefundOrderContext);
    const [currentTab, setCurrentTab] = useState(1);
    const _refundOrdersCount = {600: 0, 610: 0, 620: 0};
    const [refundOrdersCount, setRefundOrdersCount] = useState(_refundOrdersCount);
    const [selectedOrderId, setSelectedOrderId] = useState(null);

    useEffect(() => {
        refundOrderContext.getRefundOrders();
    }, []);

    const getRefundOrdersCountByStatus = (orders) => {
        for (const order of orders) {
            setRefundOrdersCount({
                ...refundOrdersCount,
                [order.status]: refundOrdersCount[order.status] + 1
            });
        }
    }

    useEffect(() => {
        if (refundOrderContext.state.orders.all !== null) {
            setRefundOrdersCount(_refundOrdersCount);
            getRefundOrdersCountByStatus(refundOrderContext.state.orders.all);
        }
    }, [refundOrderContext.state.orders]);

    const getRefundOrdersWithFilter = (e) => {
        e.preventDefault();
        const formType = e.target.getAttribute('form-type');
        refundOrderContext.searchRefundOrders(formType);
    }

    return (
        <Layout title="Refund Orders">
            <div className={styles.container}>
                <Title variant="badge" title={"İADELER"} icon={<Icons.RollbackIcon/>}/>
                <Tabs
                    current={currentTab}
                    setCurrent={setCurrentTab}
                    tabs={[
                        <TabPane key={1}>
                            <div className={styles.tab_pane_content}>
                                <h4>TÜM İADELER</h4>
                                <h5>{refundOrderContext.state.orders.all !== null && `${refundOrderContext.state.orders.all.length} PAKET`}</h5>
                            </div>
                        </TabPane>,
                        <TabPane key={2}>
                            <div className={styles.tab_pane_content}>
                                <h4>YENİ İADELER</h4>
                                <h5>{refundOrderContext.state.orders.refundStarted !== null && `${refundOrderContext.state.orders.refundStarted.length} PAKET`}</h5>
                            </div>
                        </TabPane>,
                        <TabPane key={3}>
                            <div className={styles.tab_pane_content}>
                                <h4>KARGOYA VERİLEN</h4>
                                <h5>{refundOrderContext.state.orders.onCargo !== null && `${refundOrderContext.state.orders.onCargo.length} PAKET`}</h5>
                            </div>
                        </TabPane>,
                        <TabPane key={4}>
                            <div className={styles.tab_pane_content}>
                                <h4>AKSİYON BEKLEYENLER</h4>
                                <h5>{refundOrderContext.state.orders.receivedVendor !== null && `${refundOrderContext.state.orders.receivedVendor.length} PAKET`}</h5>
                            </div>
                        </TabPane>,
                        <TabPane key={5}>
                            <div className={styles.tab_pane_content}>
                                <h4>ONAYLANANLAR</h4>
                                <h5>{refundOrderContext.state.orders.refundApproved !== null && `${refundOrderContext.state.orders.refundApproved.length} PAKET`}</h5>
                            </div>
                        </TabPane>,
                        <TabPane key={6}>
                            <div className={styles.tab_pane_content}>
                                <h4>REDDEDİLENLER</h4>
                                <h5>{refundOrderContext.state.orders.refundCanceled !== null && `${refundOrderContext.state.orders.refundCanceled.length} PAKET`}</h5>
                            </div>
                        </TabPane>,
                        <TabPane key={7}>
                            <div className={styles.tab_pane_content}>
                                <h4>KARGO OLUŞTURULANLAR</h4>
                                <h5>{refundOrderContext.state.orders.refundCreatedShipment !== null && `${refundOrderContext.state.orders.refundCreatedShipment.length} PAKET`}</h5>
                            </div>
                        </TabPane>,
                        <TabPane key={8}>
                            <div className={styles.tab_pane_content}>
                                <h4>KARGODA</h4>
                                <h5>{refundOrderContext.state.orders.refundDeliveredShipment !== null && `${refundOrderContext.state.orders.refundDeliveredShipment.length} PAKET`}</h5>
                            </div>
                        </TabPane>,
                        <TabPane key={9}>
                            <div className={styles.tab_pane_content}>
                                <h4>TESLİM EDİLDENLER</h4>
                                <h5>{refundOrderContext.state.orders.refundDelivered !== null && `${refundOrderContext.state.orders.refundDelivered.length} PAKET`}</h5>
                            </div>
                        </TabPane>,
                    ]}>
                    <TabContent key={1}>
                        <div className="tab_content">
                            <form form-type="all" onSubmit={getRefundOrdersWithFilter}
                                  className={styles.filter_section}>
                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={refundOrderContext.state.filterInputs.all.orderNo}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           refundOrderContext.setFilterInputs('all', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={async (values, _dateString) => {
                                            refundOrderContext.setFilterInputDates('all', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>

                        <CardTable variant={"refund-order"}
                                   onConfirm={(selectedId) => {
                                       setSelectedOrderId(selectedId);
                                       refundOrderContext.setOrderStatus(selectedId, 710, () => {
                                           setSearchParams({tab: '3'});
                                       });
                                   }}
                                   filters={true}
                                   pagination={{
                                       total: refundOrderContext.state.orders.all?.length || 0,
                                       defaultPageSize: 2,
                                       defaultCurrent: 1,
                                       setSizeChanger: null
                                   }} data={refundOrderContext.state.orders.all}/>

                    </TabContent>
                    <TabContent key={2}>
                        <div className="tab_content">
                            <form form-type="refundStarted" onSubmit={getRefundOrdersWithFilter}
                                  className={styles.filter_section}>
                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={refundOrderContext.state.filterInputs.all.orderNo}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           refundOrderContext.setFilterInputs('refundStarted', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={async (values, _dateString) => {
                                            refundOrderContext.setFilterInputDates('refundStarted', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>

                        <CardTable variant={"refund-order"}
                                   filters={true}
                                   onConfirm={(selectedId) => {
                                       setSelectedOrderId(selectedId);
                                       refundOrderContext.setOrderStatus(selectedId, 710, () => {
                                           setSearchParams({tab: '3'});
                                       });
                                   }}
                                   pagination={{
                                       total: refundOrderContext.state.orders.refundStarted?.length || 0,
                                       defaultPageSize: 2,
                                       defaultCurrent: 1,
                                       setSizeChanger: null
                                   }} data={refundOrderContext.state.orders.refundStarted}/>
                    </TabContent>
                    <TabContent key={3}>
                        <div className="tab_content">
                            <form form-type="onCargo" onSubmit={getRefundOrdersWithFilter}
                                  className={styles.filter_section}>
                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={refundOrderContext.state.filterInputs.all.orderNo}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           refundOrderContext.setFilterInputs('onCargo', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={async (values, _dateString) => {
                                            refundOrderContext.setFilterInputDates('onCargo', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>

                        <CardTable variant={"refund-on-cargo"}
                                   filters={true}
                                   onConfirm={(selectedId) => {
                                       setSelectedOrderId(selectedId);
                                       refundOrderContext.setOrderStatus(selectedId, 720, () => {
                                           setSearchParams({tab: '4'});
                                       });
                                   }}
                                   pagination={{
                                       total: refundOrderContext.state.orders.onCargo?.length || 0,
                                       defaultPageSize: 2,
                                       defaultCurrent: 1,
                                       setSizeChanger: null
                                   }} data={refundOrderContext.state.orders.onCargo}/>
                    </TabContent>
                    <TabContent key={4}>
                        <div className="tab_content">
                            <form form-type="receivedVendor" onSubmit={getRefundOrdersWithFilter}
                                  className={styles.filter_section}>
                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={refundOrderContext.state.filterInputs.all.orderNo}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           refundOrderContext.setFilterInputs('receivedVendor', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={async (values, _dateString) => {
                                            refundOrderContext.setFilterInputDates('receivedVendor', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>

                        <CardTable variant={"received-vendor"}
                                   filters={true} onConfirm={(selectedId) => {
                            updateModal('approveModal', true);
                            setSelectedOrderId(selectedId);
                        }} onCancel={(selectedId) => {
                            updateModal('declineModal', true);
                            setSelectedOrderId(selectedId);
                        }}
                                   pagination={{
                                       total: refundOrderContext.state.orders.receivedVendor?.length || 0,
                                       defaultPageSize: 2,
                                       defaultCurrent: 1,
                                       setSizeChanger: null
                                   }} data={refundOrderContext.state.orders.receivedVendor}/>
                    </TabContent>
                    <TabContent key={5}>
                        <div className="tab_content">
                            <form form-type="refundApproved" onSubmit={getRefundOrdersWithFilter}
                                  className={styles.filter_section}>
                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={refundOrderContext.state.filterInputs.refundApproved.orderNo}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           refundOrderContext.setFilterInputs('refundApproved', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={async (values, _dateString) => {
                                            refundOrderContext.setFilterInputDates('refundApproved', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>

                        <CardTable variant={"refund-approved"}
                                   filters={true}
                                   pagination={{
                                       total: refundOrderContext.state.orders.refundApproved?.length || 0,
                                       defaultPageSize: 2,
                                       defaultCurrent: 1,
                                       setSizeChanger: null
                                   }} data={refundOrderContext.state.orders.refundApproved}/>
                    </TabContent>
                    <TabContent key={6}>
                        <div className="tab_content">
                            <form form-type="refundCanceled" onSubmit={getRefundOrdersWithFilter}
                                  className={styles.filter_section}>
                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={refundOrderContext.state.filterInputs.refundCanceled.orderNo}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           refundOrderContext.setFilterInputs('refundCanceled', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={async (values, _dateString) => {
                                            refundOrderContext.setFilterInputDates('refundCanceled', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>

                        <CardTable variant={"refund-canceled"}
                                   filters={true}
                                   pagination={{
                                       total: refundOrderContext.state.orders.refundCanceled?.length || 0,
                                       defaultPageSize: 2,
                                       defaultCurrent: 1,
                                       setSizeChanger: null
                                   }} data={refundOrderContext.state.orders.refundCanceled}/>
                    </TabContent>
                    <TabContent key={7}>
                        <div className="tab_content">
                            <form form-type="refundCreatedShipment" onSubmit={getRefundOrdersWithFilter}
                                  className={styles.filter_section}>
                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={refundOrderContext.state.filterInputs.all.orderNo}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           refundOrderContext.setFilterInputs('refundCreatedShipment', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={async (values, _dateString) => {
                                            refundOrderContext.setFilterInputDates('refundStarted', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>

                        <CardTable variant={"refund-order"}
                                   filters={true}
                                   onConfirm={(selectedId) => {
                                       setSelectedOrderId(selectedId);
                                       refundOrderContext.setOrderStatus(selectedId, 760, () => {
                                           setSearchParams({tab: '8'});
                                       });
                                   }}
                                   pagination={{
                                       total: refundOrderContext.state.orders.refundCreatedShipment?.length || 0,
                                       defaultPageSize: 2,
                                       defaultCurrent: 1,
                                       setSizeChanger: null
                                   }} data={refundOrderContext.state.orders.refundCreatedShipment}/>
                    </TabContent>
                    <TabContent key={8}>
                        <div className="tab_content">
                            <form form-type="refundDeliveredShipment" onSubmit={getRefundOrdersWithFilter}
                                  className={styles.filter_section}>
                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={refundOrderContext.state.filterInputs.all.orderNo}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           refundOrderContext.setFilterInputs('refundDeliveredShipment', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={async (values, _dateString) => {
                                            refundOrderContext.setFilterInputDates('refundStarted', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>

                        <CardTable variant={"refund-order"}
                                   filters={true}
                                   onConfirm={(selectedId) => {
                                       setSelectedOrderId(selectedId);
                                       refundOrderContext.setOrderStatus(selectedId, 770, () => {
                                           setSearchParams({tab: '8'});
                                       });
                                   }}
                                   pagination={{
                                       total: refundOrderContext.state.orders.refundDeliveredShipment?.length || 0,
                                       defaultPageSize: 2,
                                       defaultCurrent: 1,
                                       setSizeChanger: null
                                   }} data={refundOrderContext.state.orders.refundDeliveredShipment}/>
                    </TabContent>
                    <TabContent key={9}>
                        <div className="tab_content">
                            <form form-type="refundDelivered" onSubmit={getRefundOrdersWithFilter}
                                  className={styles.filter_section}>
                                <Input label={"SİPARİŞ NO"} type={"text"}
                                       value={refundOrderContext.state.filterInputs.all.orderNo}
                                       tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                                       onChange={(e) => {
                                           refundOrderContext.setFilterInputs('refundDelivered', 'orderNo', e.target.value);
                                       }}/>

                                <div className={`input_group`}>
                                    <label>
                                        SİPARİŞ TARİHİ
                                        <Tooltip
                                            title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                                    </label>
                                    <div className="">
                                        <RangePicker onChange={async (values, _dateString) => {
                                            refundOrderContext.setFilterInputDates('refundStarted', values);
                                        }}/>
                                    </div>
                                </div>

                                <Button variant={"primary"} loading={false} disabled={false}>Ara</Button>
                                <Button variant={"primary-green"} loading={false} disabled={false}>Excel İndir</Button>
                            </form>
                        </div>

                        <CardTable variant={"refund-order"}
                                   filters={true}
                                   onConfirm={() => {
                                       return null;
                                   }}
                                   pagination={{
                                       total: refundOrderContext.state.orders.refundDelivered?.length || 0,
                                       defaultPageSize: 2,
                                       defaultCurrent: 1,
                                       setSizeChanger: null
                                   }} data={refundOrderContext.state.orders.refundDelivered}/>
                    </TabContent>
                </Tabs>
            </div>

            <Modal title={(selectedOrderId?.refundType === 2) ? "Değişim Onayı" : "İade Onayı"} show={modals.approveModal}
                   onConfirm={() => {
                       updateModal('approveModal', false);
                   }}
                   onClose={() => updateModal('approveModal', false)}
                   onCancel={() => updateModal('approveModal', false)}
                   footer
                   footerComponent={
                       <div className={"modal_footer"}>
                           <Button variant="primary-gray"
                                   onClick={() => updateModal('approveModal', false)}>Vazgeç</Button>
                           <Button onClick={() => {
                               refundOrderContext.setRefundOrderApprovement({
                                   ...selectedOrderId,
                                   approvement: 1
                               }, () => {
                                   updateModal('approveModal', false);
                               });
                           }}>{(selectedOrderId?.refundType === 2) ? "Değişimi" : "İadeyi"} Kabul Et</Button>
                       </div>
                   }
            >
                <div className={styles.approve_modal_content}>
                    <div className={styles.title}>
                        İadeyi onaylamak istediğinize emin misiniz?
                    </div>
                    <div className={styles.description}>
                        İade onayladıktan sonra iptal edilemez.
                    </div>
                </div>
            </Modal>

            <Modal title={(selectedOrderId?.refundType === 2) ? "Değişim Reddi" : "İade Reddi"} show={modals.declineModal}
                   onConfirm={() => {
                       updateModal('declineModal', false);
                   }}
                   onClose={() => updateModal('declineModal', false)}
                   onCancel={() => updateModal('declineModal', false)}
                   footer
                   footerComponent={
                       <div className={"modal_footer"}>
                           <Button variant="primary-gray"
                                   onClick={() => updateModal('declineModal', false)}>Vazgeç</Button>
                           <Button onClick={() => {
                               refundOrderContext.setRefundOrderApprovement({
                                   ...selectedOrderId,
                                   approvement: 0
                               }, () => {
                                   updateModal('declineModal', false);
                               });
                           }}>{(selectedOrderId?.refundType === 2) ? "Değişimi" : "İadeyi"} Reddet</Button>
                       </div>
                   }
            >
                <div className={styles.approve_modal_content}>
                    <div className={styles.title}>
                        İadeyi iptal etmek istediğinize emin misiniz?
                    </div>
                    <div className={styles.description}>
                        İadeyi reddetmek depo puanınızı ve satışlarınızı olumsuz etkileyecektir.
                    </div>
                </div>
            </Modal>

        </Layout>
    )
};

export default RefundOrders;
