import React, {useState, useContext, useEffect} from "react";

import 'moment/locale/tr';
import moment from 'moment';

import styles from '@dv/scss/lib/PaymentsHistory.module.scss';

import Layout from "@organisms/Layout";
import {Icons, Title, Card, Button, Input, Tooltip} from "@dv/react";
import Table, {Cell, Header, Row} from "@organisms/Table";
import RangePicker from "@antd/RangePicker";
import PayoutContext from "@contexts/Payout/PayoutContext";

const PaymentsHistory: React.FC<any> = () => {

    const payoutContext = useContext(PayoutContext);
    const payoutHistory = payoutContext.state.payoutHistory;
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();

    return (
        <Layout title="Payments History">
            <div className={styles.container}>
                <Title variant="badge" title={"GEÇMİŞ ÖDEMELER"} icon={<Icons.NewspaperIcon/>}/>
                <Card>
                    <form className={styles.search_section}>

                        {/*<Input label={"ARAMA TÜRÜ"} type={"text"}
                               value={''}
                               placeholder={"Sipariş Numarası"}
                               tooltip={<Tooltip title="Açıklama"><Icons.InfoIcon/></Tooltip>}
                               onChange={() => {
                               }}
                        />*/}

                        <div className={`input_group flex`}>
                            <label>
                                SİPARİŞ TARİHİ
                                <Tooltip
                                    title="Siparişleri tarihe göre filtrelemenize yardımcı olur."><Icons.InfoIcon/></Tooltip>
                            </label>
                            <div className="">
                                <RangePicker showTime={false} format={"DD MMMM YYYY"}
                                             onChange={(_value, _dateString) => {
                                                 if (_value[0]) {
                                                     setStartDate(_value[0]);
                                                 }
                                                 if (_value[1]) {
                                                     setEndDate(_value[1]);
                                                 }
                                             }}/>
                            </div>
                        </div>
                        <Button variant={"primary"} type={"button"} loading={false}
                                disabled={(startDate && endDate) ? false : true} onClick={() => {
                            payoutContext.getPayoutHistory(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
                        }}>Filtrele</Button>

                    </form>
                </Card>

                <div className={styles.table_wrapper}>
                    <div className={styles.table}>
                        {payoutHistory && payoutHistory?.map((el, i) => (
                            <Card key={i} data={el} variant={"payment-history"} detail={
                                <>
                                    <div className={"flex fd-column gap-10"}>
                                        {[...el?.remittances, ...el?.orders]?.map((el2, i2) => (
                                            <Card key={i2} data={el2} variant={"payment-history-order"} detail={
                                                <Table type="complex" headers={[
                                                    <Header style={{flex: 10}}>ÜRÜN ADI</Header>,
                                                    <Header
                                                        style={{flex: 3, justifyContent: 'end', textAlign: 'right'}}>SATIŞ
                                                        TUTARI</Header>,
                                                    <Header
                                                        style={{flex: 3, justifyContent: 'end', textAlign: 'right'}}>KAMPANYA
                                                        TUTARI</Header>,
                                                    <Header
                                                        style={{flex: 3, justifyContent: 'end', textAlign: 'right'}}>POS & DP KOMİSYONU</Header>,
                                                    <Header
                                                        style={{flex: 3, justifyContent: 'end', textAlign: 'right'}}>KARGO KESİNTİSİ</Header>,
                                                    <Header
                                                        style={{flex: 3, justifyContent: 'end', textAlign: 'right'}}>DP
                                                        ÖDEME</Header>,
                                                    <Header
                                                        style={{flex: 3, justifyContent: 'end', textAlign: 'right'}}>IYZICO
                                                        ÖDEME</Header>
                                                ]}>
                                                    {el2?.items && [...el2?.items].map((item, i) => (
                                                        <Row key={i}>
                                                            <Cell
                                                                style={{flex: 10, flexDirection: 'row', gap: 10, alignItems: 'center', justifyContent: 'start'}}>
                                                                <img width={50} height={50}
                                                                     src={item?.thumbnail}
                                                                     alt={item?.productName + '_thumbnail'}/>
                                                                {item?.productName} | <b>{item?.variantTitle}</b>
                                                            </Cell>
                                                            <Cell
                                                                style={{flex: 3, alignItems: 'end', paddingRight: 0}}><b>{item?.totalPrice?.toFixed(2)} ₺</b></Cell>
                                                            <Cell
                                                                style={{flex: 3, alignItems: 'end', paddingRight: 0}}><b>-{item?.campaignAppliedPrice?.toFixed(2)} ₺</b></Cell>
                                                            <Cell
                                                                style={{flex: 3, alignItems: 'end', paddingRight: 0}}><b>-{item?.commissionPrice?.toFixed(2)} ₺</b></Cell>
                                                            <Cell
                                                                style={{flex: 3, alignItems: 'end', paddingRight: 0}}><b>-{item?.cargoPrice?.toFixed(2)} ₺</b></Cell>
                                                            <Cell
                                                                style={{flex: 3, alignItems: 'end', paddingRight: 0}}><b>{item?.dpDiscountPrice?.toFixed(2)} ₺</b></Cell>
                                                            <Cell
                                                                style={{flex: 3, alignItems: 'end', paddingRight: 0}}><b>{item?.netPrice?.toFixed(2)} ₺</b></Cell>
                                                        </Row>
                                                    ))}
                                                </Table>
                                            }/>
                                        ))}
                                    </div>
                                </>
                            }/>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default PaymentsHistory;
