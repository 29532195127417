import React, {useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';

import styles from '@dv/scss/lib/Navbar.module.scss';

import AuthContext from "@contexts/Auth/AuthContext";
import DashboardContext from "@contexts/Dashboard/DashboardContext";

import {Menu, Dropdown, message} from 'antd';
import {Icons} from "@dv/react/lib";
import Logo from '@assets/images/logo.svg';
import {ENVIRONMENT_TAG} from "@utils/axiosInstances";
import {Tooltip} from "@dv/react";

const Navbar: React.FC<any> = () => {
    const authContext = useContext(AuthContext);
    const dashboardContext = useContext(DashboardContext);

    useEffect(() => {
        authContext.getExchangeRates();
    }, []);

    const ordersAndCargoMenu = (
        <Menu className={`${styles.menu} ${styles.columns1}`}>
            <Menu.Item className={styles.item} key="1"><Link to="/orders">Siparişler</Link></Menu.Item>
            <Menu.Item className={styles.item} key="2"><Link to="/cancellations/orders">İptal Edilen
                Siparişler</Link></Menu.Item>
            <Menu.Item className={styles.item} key="3"><Link to="/refund/orders">İadeler</Link></Menu.Item>
            {/*<Menu.Item className={styles.item} key="4"><Link to="/cargos">Kargolar</Link></Menu.Item>*/}
        </Menu>
    )

    const productsMenu = (
        <Menu className={`${styles.menu} ${styles.columns1}`}>
            <Menu.Item className={styles.item} key="5"><Link to="/products">Satıştaki Ürünler</Link></Menu.Item>
            <Menu.Item className={styles.item} key="6"><Link to="/products/add">Ürün Ekle</Link></Menu.Item>
            {/*<Menu.Item className={styles.item} key="7"><Link to="/product-suggestion">Ürün Öner</Link></Menu.Item>*/}
        </Menu>
    )

    const financeMenu = (
        <Menu className={`${styles.menu} ${styles.columns1}`}>
            <Menu.Item className={styles.item} key="8"><Link to="/payments">Ödemelerim</Link></Menu.Item>
            <Menu.Item className={styles.item} key="9"><Link to="/payments/remittances">Kesintiler</Link></Menu.Item>
            {/*<Menu.Item className={styles.item} key="9"><Link to="/payments/order-item-by-txid">Iyzico İşlem Numarası İle Ürün Hakedişi</Link></Menu.Item>*/}
            {/*<Menu.Item className={styles.item} key="10"><Link to="/payments/orders">Tüm Sipariş Ödemeleri</Link></Menu.Item>*/}
        </Menu>
    )

    const userMenu = (
        <Menu className={`${styles.menu} ${styles.columns1}`}>
            <Menu.Item className={styles.item} key="11"><Link to="/profile">Profil</Link></Menu.Item>
            <Menu.Item className={styles.item} key="12"><Link to="/logout">Çıkış Yap</Link></Menu.Item>
        </Menu>
    )

    return (
        <nav>
            <section className={styles.navbar}>
                <div className={styles.logo}>
                    <Link to="/"><img alt="logo" src={Logo}/></Link>
                </div>
                <div className={styles.left_bar}>
                    <ul className={styles.items}>
                        <Dropdown overlay={productsMenu}
                                  trigger={["click", "hover"]}>
                            <li className={`${styles.item}`}>
                                <Link to="#" className={styles.mini}>
                                    ÜRÜNLER <Icons.ChevronDownIcon color={"var(--secondary-gray)"}/>
                                </Link>
                            </li>
                        </Dropdown>
                        <Dropdown overlay={ordersAndCargoMenu}
                                  trigger={["click", "hover"]}>
                            <li className={`${styles.item}`}>
                                <Link to="#" className={styles.mini}>
                                    SİPARİŞ & KARGO <Icons.ChevronDownIcon color={"var(--secondary-gray)"}/>
                                </Link>
                            </li>
                        </Dropdown>
                        {/*<Dropdown overlay={financeMenu}*/}
                        {/*          trigger={["click", "hover"]}>*/}
                        {/*    <li className={`${styles.item}`}>*/}
                        {/*        <Link to="#" className={styles.mini}>*/}
                        {/*            FİNANS <Icons.ChevronDownIcon color={"var(--secondary-gray)"}/>*/}
                        {/*        </Link>*/}
                        {/*    </li>*/}
                        {/*</Dropdown>*/}
                        <li className={styles.item}>
                            <Link to="/payments">FİNANS</Link>
                        </li>
                        <li className={styles.item}>
                            <Link to="/campaigns">KAMPANYALARIM</Link>
                        </li>
                        {/*
                        <li className={styles.item}>
                            <Link to="/vendor-management">MAĞAZA YÖNETİMİ</Link>
                        </li>
                        <li className={styles.item}>
                            <Link to="/inventory-management">ENVANTER YÖNETİMİ</Link>
                        </li>
                        */}
                    </ul>
                </div>
                <div className={styles.right_bar}>
                    <ul className={`${styles.items}`}>
                        <li className={`${styles.item} ${styles.no_hover}`}>
                            <div className={"tags"}>
                                {/*
                                    (Array.isArray(authContext?.state?.exchangeRates?.yesterday) && authContext?.state?.exchangeRates?.yesterday) &&
                                        <label className={"flex fd-column ta-right"}>
                                            <span><b><u>Dünün Kuru</u></b></span>
                                            {authContext?.state?.exchangeRates?.yesterday?.slice(0,2)?.map((el,i) => (<span key={i}><b>{el?.short_name}:</b> {el?.rate}</span>))}
                                        </label>
                                */}
                                {
                                    (Array.isArray(authContext?.state?.exchangeRates?.today) && authContext?.state?.exchangeRates?.today) &&
                                    <label className={"flex fd-column ta-right"}>
                                        <span className={"flex ai-center gap-5"}><b><u>Mevcut Kur</u></b> <Tooltip placement={"bottom"} title="Döviz kurları, her gün saat 16:00'da merkez bankası efektif satış kuru üzerinden güncellenir. Merkez bankası kuru ile piyasadaki kur arasındaki makas açıldığında, elle anlık müdahale edilerek piyasa kuru uygulanır"><Icons.InfoIcon color={"white"}/></Tooltip></span>
                                        {authContext?.state?.exchangeRates?.today?.length > 1 ?
                                            authContext?.state?.exchangeRates?.today?.map((el,i) => (<span key={i}><b>{el?.short_name}:</b> {el?.rate?.toFixed(4)}</span>))
                                            :
                                            authContext?.state?.exchangeRates?.yesterday?.slice(0,2)?.map((el,i) => (<span key={i}><b>{el?.short_name}:</b> {el?.rate?.toFixed(4)}</span>))
                                        }
                                    </label>
                                }
                            </div>
                        </li>
                        {ENVIRONMENT_TAG === 'DEV' &&
                            <li className={styles.item}>
                                <div className={"tags"}>
                                    <Tooltip
                                        placement={"bottom"}
                                        title={"Şu an geliştirici ortamındasınız. Dilediğiniz gibi test yapabilirsiniz :)"}>
                                        <div className={"cursor_pointer tag tag-m orange_secondary"}>
                                            <span>DEV</span>
                                        </div>
                                    </Tooltip>
                                </div>
                            </li>
                        }
                        <li className={styles.item}>
                            <Link to="#"><Icons.BellIcon/></Link>
                        </li>
                        <Dropdown overlay={userMenu}
                                  trigger={["click", "hover"]}>
                            <li className={styles.item}>
                                <Link to="#">
                                    <div className={styles.user_info}>
                                        <Icons.UserIcon color={"var(--secondary-gray)"}/>
                                        <div className={styles.name}>
                                            <p>{authContext.state?.user?.name}</p>
                                        </div>
                                        <div className={styles.mini}>
                                            <Icons.ChevronDownIcon color={"var(--secondary-gray)"}/>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        </Dropdown>

                    </ul>
                </div>
            </section>
        </nav>
    )
}

export default Navbar;
