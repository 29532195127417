import React, {useContext, useEffect, useState} from 'react';

import styles from '@dv/scss/lib/Dashboard.module.scss';

import Layout from "@organisms/Layout";
import {Title, Icons, Card, Anchor, Checkbox, Tag, Button, Input} from '@dv/react/lib';
import {Link} from 'react-router-dom';

import DashboardContext from "@contexts/Dashboard/DashboardContext";
import AuthContext from "@contexts/Auth/AuthContext";
import Loader from "@organisms/Loader";
import moment from "moment";
import IyzicoAgreementModal from "@organisms/Modal/IyzicoAgreementModal";

const DashboardPage: React.FC<any> = () => {

    const authContext = useContext(AuthContext);
    const dashboardContext = useContext(DashboardContext);
    // const [iyzicoAgreementModal, setIyzicoAgreementModal] = useState(false);

    useEffect(() => {
        dashboardContext.getData();
    }, []);

    // useEffect(() => {
    //     if (authContext.state.user?.iyzicoAgreementApproved === 0) {
    //         setIyzicoAgreementModal(true);
    //     }
    // }, [authContext.state.user])

    const [minPrices, setMinPrices] = useState({
        minBuyPrice: null,
        minFreeCargoPrice: null,
        minFreeCargoPriceButton: true,
        minBuyPriceButton: true
    });

    useEffect(() => {
        setMinPrices({
            ...minPrices,
            minBuyPrice: authContext.state.user?.minBuyPrice,
            minFreeCargoPrice: authContext.state.user?.minFreeCargoPrice
        });
        console.log('user', authContext.state.user);
    }, [authContext.state.user]);

    const submitMinPrices = async () => {
        authContext.updateMe({
            minBuyPrice: minPrices.minBuyPrice || authContext.state.user?.minBuyPrice,
            minFreeCargoPrice: minPrices.minFreeCargoPrice || (minPrices.minFreeCargoPrice == 0 || minPrices.minFreeCargoPrice == '' ? 0 : authContext.state.user?.minFreeCargoPrice)
        });
    };

    useEffect(() => {
        console.log(minPrices);
    }, [minPrices])

    useEffect(() => {
        if (authContext.state.user?.minFreeCargoPrice == minPrices.minFreeCargoPrice) {
            setMinPrices({
                ...minPrices,
                minFreeCargoPriceButton: true
            });
        } else {
            setMinPrices({
                ...minPrices,
                minFreeCargoPriceButton: false
            });
        }
    }, [minPrices.minFreeCargoPrice]);

    useEffect(() => {
        if (authContext.state.user?.minBuyPrice == minPrices.minBuyPrice) {
            setMinPrices({
                ...minPrices,
                minBuyPriceButton: true
            });
        } else {
            setMinPrices({
                ...minPrices,
                minBuyPriceButton: false
            });
        }
    }, [minPrices.minBuyPrice]);

    const data = dashboardContext.state.data;

    return (
        <Layout title="Dashboard">
            <div className={styles.container}>
                <section className={styles.section}>

                    <div className={styles.block}>
                        <Title variant="badge" title={"SİPARİŞLER"} icon={<Icons.FastDeliveryIcon/>}/>
                        <div className={styles.cards}>
                            <Card>
                                {data.orderMetrics.new !== null ? <div className={styles.dashboard_card}>
                                    <h5>YENİ SİPARİŞLER</h5>
                                    <h3 className={styles.amount}>{data.orderMetrics.new} ADET</h3>
                                    <Anchor href="/orders?tab=2" variant="see_all"/>
                                </div> : <Loader type="blockChart"/>}
                            </Card>
                            <Card>
                                {data.orderMetrics.readyToShipping !== null ? <div className={styles.dashboard_card}>
                                    <h5>KARGOLANACAKLAR</h5>
                                    <h3 className={styles.amount}>{data.orderMetrics.readyToShipping} ADET</h3>
                                    <Anchor href="/orders?tab=4" variant="see_all"/>
                                </div> : <Loader type="blockChart"/>}
                            </Card>
                            <Card>
                                {data.orderMetrics.shipped !== null ? <div className={styles.dashboard_card}>
                                    <h5>KARGOLANANLAR</h5>
                                    <h3 className={styles.amount}>{data.orderMetrics.shipped} ADET</h3>
                                    <Anchor href="/orders?tab=5" variant="see_all"/>
                                </div> : <Loader type="blockChart"/>}
                            </Card>
                            <Card>
                                {data.orderMetrics.canceled.metric !== null ? <div className={styles.dashboard_card}>
                                    <h5>İPTAL EDİLENLER</h5>
                                    <h5>%{data.orderMetrics.canceled.ratio} <Icons.InfoIcon/></h5>
                                    <h3>{data.orderMetrics.canceled.metric} ADET</h3>
                                    <Anchor href="/cancellations/orders" variant="see_all"/>
                                </div> : <Loader type="blockChartWithRatio"/>}
                            </Card>

                        </div>
                    </div>

                    <div className={styles.block}>
                        <Title variant="badge" title={"SATIŞ PERFORMANS"} icon={<Icons.SalesGraphicIcon/>}/>
                        <div className={styles.cards}>
                            <Card>
                                {data.performanceMetrics.today.metric !== null ? <div className={styles.dashboard_card}>
                                    <h5>BUGÜNKİ SATIŞLARIM</h5>
                                    YAKINDA
                                    {/*
                                    <h5>%{data.performanceMetrics.today.ratio} <Icons.InfoIcon/></h5>
                                    <h3 className={styles.amount}>{data.performanceMetrics.today.metric} TL</h3>
                                    */}
                                </div> : <Loader type="blockChartWithRatio"/>}
                            </Card>
                            <Card>
                                {data.performanceMetrics.last7Days.metric !== null ?
                                    <div className={styles.dashboard_card}>
                                        <h5>SON BİR HAFTALIK SATIŞLARIM</h5>
                                        YAKINDA
                                        {/*
                                        <h5>%{data.performanceMetrics.last7Days.ratio} <Icons.InfoIcon/></h5>
                                        <h3 className={styles.amount}>{data.performanceMetrics.last7Days.metric} TL</h3>
                                        */}
                                    </div> : <Loader type="blockChart"/>}
                            </Card>
                            <Card>
                                {data.performanceMetrics.last30Days.metric !== null ?
                                    <div className={styles.dashboard_card}>
                                        <h5>SON 30 GÜNLÜK SATIŞLARIM</h5>
                                        YAKINDA
                                        {/*
                                        <h5>%{data.performanceMetrics.last30Days.ratio} <Icons.InfoIcon/></h5>
                                        <h3 className={styles.amount}>{data.performanceMetrics.last30Days.metric?.toFixed(2)} TL</h3>
                                        */}
                                    </div> : <Loader type="blockChart"/>}
                            </Card>

                            <Card>
                                {data.performanceMetrics.nextProgressPayment.metric !== null ?
                                    <div className={styles.dashboard_card}>
                                        <h5>BİR SONRAKİ ALACAĞIM</h5>
                                        <h5>{moment(data.performanceMetrics.nextProgressPayment.date, 'DD-MM-YYYY HH:mm:ss').format('DD MMMM')}
                                            <Icons.InfoIcon/></h5>
                                        <h3>{data.performanceMetrics.nextProgressPayment.metric?.toFixed(2)} TL</h3>
                                        <Anchor href="/payments" variant="see_all">Detaylar</Anchor>
                                    </div> : <Loader type="blockChartWithRatio"/>}
                            </Card>

                        </div>
                    </div>

                    <div className={styles.block}>
                        <Title variant="badge" title={"KARGO KAMPANYASI"} icon={<Icons.CargoTruckIcon/>}/>
                        <div className={styles.big_card}>
                            <Card>
                                <h5>SATICI ÖDEMELİ KARGO KAMPANYASI SEÇİMİ</h5>

                                <h3 className={`${styles.amount} flex ai-center gap-20`}>
                                    <Tag icon="DeliveryBoxIcon" variant={'warning'}
                                         label={
                                             authContext.state.user?.minFreeCargoPrice !== null ?
                                                 authContext.state.user?.minFreeCargoPrice === 0 ?
                                                     <span>
                                                         Ücretsiz Kargo
                                                     </span>
                                                     :
                                                     authContext.state.user?.minFreeCargoPrice === -1 ?
                                                         <span>
                                                             Ücretli Kargo
                                                         </span>
                                                         :
                                                         <span>
                                                             <b>{authContext.state.user?.minFreeCargoPrice} TL</b> ve üzeri kargo bedava
                                                         </span>
                                                 : 'Henüz Belirlenmemiş'}/>
                                </h3>

                                <h5>TUTARLAR</h5>

                                <div className={styles.checkboxes}>
                                    <Checkbox id="1" name="cargoCampaignOptions"
                                              checked={minPrices.minFreeCargoPrice == 0}
                                              onChange={_e => {
                                                  setMinPrices({
                                                      ...minPrices,
                                                      minFreeCargoPrice: 0
                                                  });
                                              }}
                                              label={<Tag icon="DeliveryBoxIcon" variant={"warning"}
                                                          label={"Ücretsiz Kargo"}/>}/>
                                    <Checkbox id="2" name="cargoCampaignOptions" onChange={_e => {
                                        setMinPrices({
                                            ...minPrices,
                                            minFreeCargoPrice: -1
                                        });
                                    }}
                                              checked={minPrices.minFreeCargoPrice == -1}
                                              label={<Tag icon="DeliveryBoxIcon" variant={"warning"}
                                                          label={"Ücretli Kargo"}/>}
                                    />
                                    <Checkbox id="3" name="cargoCampaignOptions" onChange={_e => {
                                        setMinPrices({
                                            ...minPrices,
                                            minFreeCargoPrice: (minPrices.minFreeCargoPrice !== 0 && minPrices.minFreeCargoPrice !== -1) ? minPrices.minFreeCargoPrice : null
                                        });
                                    }}
                                              checked={minPrices.minFreeCargoPrice !== 0 && minPrices.minFreeCargoPrice !== -1}
                                              label={<Tag icon="DeliveryBoxIcon" variant={"warning"}
                                                          label={"Belirli Tutar"}/>}
                                    />
                                </div>


                                <div className={styles.card_input}>
                                    {minPrices.minFreeCargoPrice !== 0 && minPrices.minFreeCargoPrice !== -1 &&
                                        <Input min={1} type={"number"} placeholder={"Tutar giriniz"}
                                               value={minPrices.minFreeCargoPrice} onChange={e => {
                                            const value = Math.max(1, Math.min(10000000, Number(e.target.value)));
                                            setMinPrices({
                                                ...minPrices,
                                                minFreeCargoPrice: value
                                            });
                                        }}/>
                                    }
                                    <Button disabled={false/*minPrices.minFreeCargoPriceButton*/} onClick={() => {
                                        submitMinPrices();
                                    }}>Güncelle</Button>
                                </div>


                                {/*
                                <Checkbox id="1" name="cargoCampaignOptions"
                                          label={<Tag icon="DeliveryBoxIcon" label={"Ücretsiz Kargo"}/>}/>
                                <Checkbox id="2" name="cargoCampaignOptions"
                                          label={<Tag icon="DeliveryBoxIcon" label={"350 TL ve üzeri kargo bedava"}/>}
                                />
                                <Checkbox id="3" name="cargoCampaignOptions"
                                          label={<Tag icon="DeliveryBoxIcon" label={"500 TL ve üzeri kargo bedava"}/>}
                                />
                                <Checkbox id="4" name="cargoCampaignOptions"
                                          label={<Tag icon="DeliveryBoxIcon" label={"700 TL ve üzeri kargo bedava"}/>}
                                />
                                <Checkbox id="5" name="cargoCampaignOptions"
                                          label={"Kargo kampanyası yapmak istemiyorum"}/>
                                          */}
                            </Card>
                        </div>
                    </div>

                </section>
                <section className={styles.section}>

                    <div className={styles.block}>
                        <Title variant="badge" title={"ÜRÜNLERİM"} icon={<Icons.ProductIcon/>}/>
                        <div className={styles.cards}>
                            <Card>
                                {data.productMetrics.listing !== null ? <div className={styles.dashboard_card}>
                                    <Icons.AddProductIcon/>
                                    <Anchor href="/products/add" variant="see_all">Ürün Ekle</Anchor>
                                </div> : <Loader type="blockChart"/>}
                            </Card>
                            <Card>
                                {data.productMetrics.listing !== null ? <div className={styles.dashboard_card}>
                                    <h5>SATIŞTA</h5>
                                    <h3>{data.productMetrics.listing} ADET</h3>
                                    <Anchor href="/products" variant="see_all"/>
                                </div> : <Loader type="blockChartWithRatio"/>}
                            </Card>
                            <Card>
                                {data.productMetrics.outOfStock !== null ? <div className={styles.dashboard_card}>
                                    <h5>TÜKENEN</h5>
                                    <h5>STOĞU YOK<Icons.InfoIcon/></h5>
                                    <h3>{data.productMetrics.outOfStock} ADET</h3>
                                    <Anchor href="/products" variant="see_all"/>
                                </div> : <Loader type="blockChartWithRatio"/>}
                            </Card>
                            <Card>
                                {data.productMetrics.nonListing !== null ? <div className={styles.dashboard_card}>
                                    <h5>KRİTİK STOK</h5>
                                    <h5>STOĞU 5&quot;İN ALTINA DÜŞEN<Icons.InfoIcon/></h5>
                                    <h3 className={styles.amount}>{data.productMetrics.criticalStock} ADET</h3>
                                </div> : <Loader type="blockChart"/>}
                            </Card>
                        </div>
                    </div>

                    <div className={styles.block}>
                        <Title variant="badge" title={"KALİTE METRİKLERİ"} icon={<Icons.QualityDiamondIcon/>}/>
                        <div className={styles.big_card}>
                            <Card>
                                {data.qualityMetrics.returnRatio !== null ? <div className={styles.metrics}>
                                    <div className={styles.metrics_block}>
                                        <h5>GECİKEN SİPARİŞLERİM</h5>
                                        <h3>{data.qualityMetrics.lateOrders} ADET</h3>
                                        <h5>AKSİYON BEKLEYEN İADELERİM</h5>
                                        <h3>{data.qualityMetrics.pendingActionsOfReturn} ADET</h3>
                                    </div>
                                    <div className={styles.metrics_block}>
                                        <h5>ORTALAMA KARGOYA TESLİM SÜREM <Icons.InfoIcon/></h5>
                                        <h3>{data.qualityMetrics.averageDeliveryTimeToCargo}</h3>
                                        <h5>TEDARİK EDEMEME ORANI <Icons.InfoIcon/></h5>
                                        <h3>YAKINDA</h3>
                                        {/*
                                        <h3 className={styles.red}>%{data.qualityMetrics.nonSupplyRatio}</h3>
                                        */}
                                        <h5>İADE ORANIM <Icons.InfoIcon/></h5>
                                        <h3 className={styles.red}>%{data.qualityMetrics.returnRatio}</h3>
                                    </div>
                                </div> : <div>
                                    <Loader type="actionRequestMetrics"/>
                                    <Loader type="actionRequestMetrics"/>
                                </div>}
                            </Card>
                        </div>
                    </div>

                    <div className={styles.block}>
                        <Title variant="badge" title={"SEPET TUTARI"} icon={<Icons.Basket3Icon/>}/>
                        <div className={styles.big_card}>
                            <Card>
                                <h5>MİNİMUM ALIŞVERİŞ TUTARI</h5>

                                <h3 className={`${styles.amount} flex ai-center gap-20`}>
                                    <Tag icon="DeliveryBoxIcon" variant={'warning'}
                                         label={
                                             authContext.state.user?.minBuyPrice ?
                                                 <span>
                                            <b>{authContext.state.user?.minBuyPrice} TL</b> ve üzeri
                                        </span> : 'Henüz Belirlenmemiş'}/>
                                </h3>

                                <div className={styles.card_input}>
                                    <Input type={"text"} placeholder={"Tutar giriniz"} value={minPrices.minBuyPrice}
                                           onChange={e => {
                                               setMinPrices({
                                                   ...minPrices,
                                                   minBuyPrice: e.target.value
                                               });
                                           }}/>
                                    <Button onClick={() => {
                                        submitMinPrices();
                                    }} disabled={minPrices.minBuyPriceButton}>Güncelle</Button>
                                </div>

                                {/*
                                <Checkbox id="6" name="basketValueOptions"
                                          label={<Tag label={"100 TL ve üzeri"}/>}/>
                                <Checkbox id="7" name="basketValueOptions"
                                          label={<Tag label={"200 TL ve üzeri"}/>}
                                />
                                <Checkbox id="8" name="basketValueOptions"
                                          label={<Tag label={"300 TL ve üzeri"}/>}
                                />
                                <Checkbox id="9" name="basketValueOptions"
                                          label={<Tag label={"400 TL ve üzeri"}/>}
                                />
                                <Checkbox id="10" name="basketValueOptions"
                                          label={"Sepet tutarında bir kısıtlama yok"}/>
                                          */}
                            </Card>
                        </div>
                    </div>

                </section>
                <section className={styles.section}>

                    <div className={styles.block}>
                        <Title variant="badge" title={"DEPO PUANI"} icon={<Icons.VendorStoreIcon/>}/>
                        <div className={`${styles.cards} ${styles.column}`}>
                            <div className={styles.container_with_icon}>
                                <Card>
                                    {data.vendorRating !== null ? <div className={styles.container_with_icon_block}>
                                        <div className={styles.icon}>
                                            <div className={styles.rating}>
                                                {data.vendorRating}
                                            </div>
                                        </div>
                                        <div className={styles.container}>
                                            <p>Dentalpiyasa.com'da üst sıralara çıkmak için satıcı puanının çok önemli
                                                olduğunu unutma</p>
                                            <Anchor href="#" variant="see_all">Neler Yapabileceğini Öğren</Anchor>
                                        </div>
                                    </div> : <Loader type={"ratingMetric"}/>}
                                </Card>
                            </div>
                            {/*
                            <div className={styles.container_with_icon}>
                                <Card>
                                    {data.questions.answered !== null ?
                                        <div className={styles.container_with_icon_block}>

                                            <div className={styles.icon}>
                                                <Icons.QuestionMarkIcon/>
                                            </div>
                                            <div className={styles.container}>
                                                <h5>ÜRÜN SORULARI <Icons.InfoIcon/></h5>
                                                <div className={styles.questions}>
                                                    <span>{data.questions.answered} CEVAPLANAN SORU</span>
                                                    <span>{data.questions.pendingAnswer} CEVAP BEKLEYEN</span>
                                                </div>
                                                <Anchor href="#" variant="see_all"/>
                                            </div>

                                        </div> : <Loader type="questionsMetric"/>
                                    }
                                </Card>
                            </div>
                            */}
                            {/*
                            <div className={styles.requests}>
                                <Card>
                                    <div className={styles.requests_block}>
                                        <Title variant="badge" title={"AKSİYON BEKLEYEN TALEPLER"}
                                               icon={<Icons.NewspaperIcon/>}
                                               noBorder/>

                                        YAKINDA
                                        data.vendorRating !== null ?
                                            <div className={styles.requests_block_container}>
                                                <div className={styles.request}>
                                                    <h5>Neler Yapabileceğini Öğren</h5>
                                                    <p>Dentalpiyasa.com'da üst sıralara çıkmak için satıcı puanının çok
                                                        önemli olduğunu unutma</p>
                                                    <span className={styles.date}>18 Mart 2022 18:35</span>
                                                </div>
                                                <div className={styles.request}>
                                                    <h5>Neler Yapabileceğini Öğren</h5>
                                                    <p>Dentalpiyasa.com'da üst sıralara çıkmak için satıcı puanının çok
                                                        önemli olduğunu unutma</p>
                                                    <span className={styles.date}>18 Mart 2022 18:35</span>
                                                </div>
                                                <div className={styles.request}>
                                                    <h5>Neler Yapabileceğini Öğren</h5>
                                                    <p>Dentalpiyasa.com'da üst sıralara çıkmak için satıcı puanının çok
                                                        önemli olduğunu unutma</p>
                                                    <span className={styles.date}>18 Mart 2022 18:35</span>
                                                </div>
                                            </div> : <div>
                                                <Loader type="actionRequestMetrics"/>
                                                <Loader type="actionRequestMetrics"/>
                                                <Loader type="actionRequestMetrics"/>
                                            </div>


                                    </div>
                                </Card>
                            </div>
                            */}
                            <div className={styles.stocks}>
                                <Card>
                                    <div className={styles.stocks_block}>
                                        <div className={styles.title}>
                                            <Title variant="badge" title={"5 ve ALTI STOKTAKİ ÜRÜNLER"}
                                                   icon={<Icons.NewspaperIcon/>}
                                                   noBorder/>
                                            <Anchor href="/products-below-5" variant="see_all">Tümünü Gör</Anchor>
                                        </div>
                                        {
                                            data?.below5StockProducts?.length > 0 ?
                                                data?.below5StockProducts?.map(el => (
                                                    <Link to={`/products/${el?.base_product_id}/add`}>
                                                        <div className={styles.stocks_block_container}>
                                                            <div className={styles.img}>
                                                                <img src={el?.thumbnail} width={50} height={50}/>
                                                            </div>
                                                            <div className={styles.content}>
                                                                <div className={styles.name}>
                                                                    <span>{el?.product_name}</span>
                                                                </div>
                                                                <div className={styles.description}>
                                                                    <span
                                                                        className={styles.amount}>{el?.amount} Adet</span>
                                                                    <span
                                                                        className={styles.price}>{el?.currency_price}{el?.currency}</span>
                                                                </div>
                                                            </div>
                                                            <div className={styles.end_line}>
                                                                <Icons.ChevronRightCircleIcon/>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))
                                                :
                                                'Stoğu azalan ürününüz yok.'
                                        }
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>

                </section>
            </div>

            {/*<IyzicoAgreementModal show={iyzicoAgreementModal} setShow={setIyzicoAgreementModal}*/}
            {/*                      title={"İyzico Satıcı Sözleşmesi"} onConfirm={() => {*/}
            {/*    authContext.approveAgreement(() => {*/}
            {/*        setIyzicoAgreementModal(false);*/}
            {/*    })*/}
            {/*}}/>*/}

        </Layout>
    )
}

export default DashboardPage;
