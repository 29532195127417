import {useEffect, useState} from "react";

// 3rd Libraries
import {ConfigProvider} from 'antd';
import trTR from 'antd/lib/locale/tr_TR';
// import {YMInitializer} from 'react-yandex-metrika';

// Routing
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import RequireAuth from "@organisms/RequireAuth";
import RequireAuthF from "@organisms/RequireAuthF";

// Components
import Login from '@pages/Login';
import Logout from '@pages/Logout';
import Register from '@pages/Register';
import Dashboard from '@pages/Dashboard';
import ProductsBelow5 from '@pages/ProductsBelow5';
import Orders from '@pages/Orders';
import ChooseProduct from "@pages/AddProduct";
import AddProduct from '@pages/AddProduct/AddProduct';
import UpdateProduct from '@pages/Products/UpdateProduct';
import Products from '@pages/Products';
import Profile from '@pages/Profile';
import Payments from '@pages/Payments';
import PaymentsHistory from "@pages/Payments/PaymentsHistory";
import PaymentRemittances from '@pages/Payments/Remittances';
import OrderPayments from "@pages/Payments/OrderPayments";
import CanceledOrders from "@pages/CanceledOrders";
import RefundOrders from "@pages/RefundOrders";
import ProductSuggestion from "@pages/ProductSuggestion";
import Campaigns from "@pages/Campaigns";
import AddCampaign from "@pages/AddCampaign";
import CargoPrint from "@pages/CargoPrint";
import PrintTest from "@pages/CargoPrint/PrintTest";
import PrintTestPublic from "@pages/CargoPrint/PrintTestPublic";
import LoginAsVendor from "@pages/LoginAsVendor";
import OrderItemByTxId from "@pages/Payments/OrderItemByTxId";

// Contexts
import AuthState from '@contexts/Auth/AuthState';
import UserState from "@contexts/User/UserState";
import DashboardState from '@contexts/Dashboard/DashboardState';
import AddProductState from "@contexts/AddProduct/AddProductState";
import ProductState from '@contexts/Product/ProductState';
import OrderState from "@contexts/Order/OrderState";
import PayoutState from "@contexts/Payout/PayoutState";
import CampaignState from "@contexts/Campaign/CampaignState";
import CanceledOrderState from "@contexts/CanceledOrder/CanceledOrderState";
import RefundOrderState from "@contexts/RefundOrder/RefundOrderState";

// Utils
import axiosAPI from "@utils/axiosAPI";
import setAuthToken from "@utils/setAuthToken";

// CSS
import './App.css';
import 'antd/dist/antd.less';
import '@dv/scss/lib/global.css';

function App() {

    const [isAuthenticated, setUserHasAuthenticated] = useState(undefined);
    const [user, setUser] = useState(undefined);

    useEffect(() => {
        auth();
    }, []);

    const auth = async () => {
        const token = localStorage.getItem('dp_vendor_token');
        try {
            const res = await axiosAPI({
                url: `/vendor/me`,
                requestMethod: "GET",
                token: token
            });
            setAuthToken(token);
            if (res?.result === 'success') {
                setUser(res?.data);
            }
            console.log('RES RESULT!', res);

            if (res?.result === 'success') {
                if (res?.data?.isVerified === 1 && res?.data?.isConfirmed === 1) {
                    setUserHasAuthenticated(true);
                } else {
                    setUserHasAuthenticated(false);
                }
            } else {
                setUserHasAuthenticated(false);
            }

        } catch (e) {
            console.log('error?', e);
            setUserHasAuthenticated(false);
        }
    }

    return (
        <AuthState>
            <UserState>
                <ConfigProvider locale={trTR}>
                    <Router basename={'/'}>
                        {/*
                    <YMInitializer accounts={[92236584]} options={{
                        clickmap: true,
                        trackLinks: true,
                        accurateTrackBounce: true,
                        webvisor: true,
                        ecommerce: "dataLayer"
                    }}/>
                    */}
                        <Routes>
                            <Route path="login" element={<Login></Login>}/>
                            <Route path="logout" element={<RequireAuth user={user}
                                                                       isAuthenticated={isAuthenticated}><Logout></Logout></RequireAuth>}/>
                            <Route path="login-as-vendor/:token" element={<LoginAsVendor/>}/>
                            <Route path="register" element={<Register></Register>}/>
                            <Route path="" element={
                                <RequireAuth user={user} isAuthenticated={isAuthenticated}>
                                    <DashboardState>
                                        <Dashboard/>
                                    </DashboardState>
                                </RequireAuth>}/>
                            <Route path="/products-below-5" element={
                                <RequireAuth user={user} isAuthenticated={isAuthenticated}>
                                    <DashboardState>
                                        <ProductsBelow5/>
                                    </DashboardState>
                                </RequireAuth>}/>
                            <Route path="orders"
                                   element={
                                       <RequireAuth user={user} isAuthenticated={isAuthenticated}>
                                           <OrderState>
                                               <Orders/>
                                           </OrderState>
                                       </RequireAuth>
                                   }/>
                            <Route path="orders/:orderId/cargo-print"
                                   element={<RequireAuth user={user}
                                                         isAuthenticated={isAuthenticated}><CargoPrint></CargoPrint></RequireAuth>}/>
                            <Route path="cargo-print-test"
                                   element={<RequireAuth user={user}
                                                         isAuthenticated={isAuthenticated}><PrintTest></PrintTest></RequireAuth>}/>

                            <Route path="cargo-print-test-public" element={<PrintTestPublic/>}/>
                            <Route path="products"
                                   element={<RequireAuth user={user} isAuthenticated={isAuthenticated}>
                                       <ProductState>
                                           <Products/>
                                       </ProductState>
                                   </RequireAuth>}/>
                            <Route path="products/add" element={
                                <RequireAuth user={user} isAuthenticated={isAuthenticated}>
                                    <AddProductState>
                                        <ChooseProduct/>
                                    </AddProductState>
                                </RequireAuth>}/>
                            <Route path="products/:productId/add"
                                   element={<RequireAuth user={user}
                                                         isAuthenticated={isAuthenticated}><AddProductState><AddProduct/></AddProductState></RequireAuth>}/>
                            <Route path="products/:productId/update"
                                   element={<RequireAuth user={user} isAuthenticated={isAuthenticated}>
                                       <ProductState>
                                           <UpdateProduct/>
                                       </ProductState>
                                   </RequireAuth>}/>
                            <Route path="profile"
                                   element={<RequireAuth user={user}
                                                         isAuthenticated={isAuthenticated}><Profile/></RequireAuth>}/>
                            <Route path="payments" element={
                                <RequireAuth user={user} isAuthenticated={isAuthenticated}>
                                    <PayoutState>
                                        <Payments/>
                                    </PayoutState>
                                </RequireAuth>}/>
                            <Route path="payments/history" element={
                                <RequireAuth user={user} isAuthenticated={isAuthenticated}>
                                    <PayoutState>
                                        <PaymentsHistory/>
                                    </PayoutState>
                                </RequireAuth>}/>
                            <Route path="payments/remittances" element={
                                <RequireAuth user={user} isAuthenticated={isAuthenticated}>
                                    <PayoutState>
                                        <PaymentRemittances/>
                                    </PayoutState>
                                </RequireAuth>}/>
                            <Route path="payments/orders"
                                   element={
                                       <RequireAuth user={user} isAuthenticated={isAuthenticated}>
                                           <PayoutState>
                                               <OrderPayments/>
                                           </PayoutState>
                                       </RequireAuth>}/>
                            <Route path={"payments/order-item-by-txid"} element={
                                <RequireAuth user={user} isAuthenticated={isAuthenticated}>
                                    <PayoutState>
                                        <OrderItemByTxId/>
                                    </PayoutState>
                                </RequireAuth>
                            }/>
                            <Route path="cancellations/orders"
                                   element={
                                       <RequireAuth user={user} isAuthenticated={isAuthenticated}>
                                           <CanceledOrderState>
                                               <CanceledOrders/>
                                           </CanceledOrderState>
                                       </RequireAuth>
                                   }/>
                            <Route path="refund/orders"
                                   element={
                                       <RequireAuth user={user} isAuthenticated={isAuthenticated}>
                                           <RefundOrderState>
                                               <RefundOrders/>
                                           </RefundOrderState>
                                       </RequireAuth>}/>
                            <Route path="product-suggestion"
                                   element={<RequireAuth user={user}
                                                         isAuthenticated={isAuthenticated}><ProductSuggestion/></RequireAuth>}/>
                            <Route path="campaigns"
                                   element={<RequireAuth user={user} isAuthenticated={isAuthenticated}>
                                       <CampaignState>
                                           <Campaigns/>
                                       </CampaignState>
                                   </RequireAuth>}/>
                            <Route path="campaigns/add"
                                   element={
                                       <RequireAuth user={user} isAuthenticated={isAuthenticated}>
                                           <CampaignState>
                                               <AddCampaign/>
                                           </CampaignState>
                                       </RequireAuth>}
                            />
                        </Routes>
                    </Router>
                </ConfigProvider>
            </UserState>
        </AuthState>
    );
}

export default App;
