import React, {useState, useContext, useEffect} from "react";

import 'moment/locale/tr';
import moment from 'moment';

import styles from '@dv/scss/lib/PaymentsHistory.module.scss';

import Layout from "@organisms/Layout";
import {Icons, Title, Card, Button, Input, Tooltip} from "@dv/react";
import Table, {Cell, Header, Row} from "@organisms/Table";
import RangePicker from "@antd/RangePicker";
import PayoutContext from "@contexts/Payout/PayoutContext";

const PaymentRemittances: React.FC<any> = () => {

    const payoutContext = useContext(PayoutContext);
    const remittances = payoutContext.state.remittances;
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();

    useEffect(() => {
        payoutContext.getVendorRemittances();
    }, []);

    return (
        <Layout title="Kesintiler">
            <div className={styles.container}>
                <Title variant="badge" title={"KESİNTİLER"} icon={<Icons.NewspaperIcon/>}/>

                <div className={styles.table_wrapper}>
                    <div className={styles.table}>


                        <>
                            <div className={"flex fd-column gap-10"}>

                                <Table type="complex" headers={[
                                    <Header style={{flex: 3}}>KESİNTİ TARİHİ</Header>,
                                    <Header style={{flex: 10}}>AÇIKLAMA</Header>,
                                    <Header style={{flex: 3, justifyContent: 'end', textAlign: 'right'}}>KESİNTİ
                                        TUTARI</Header>,
                                ]}>
                                    {remittances?.map((item, i) => (
                                        <Row key={i}>
                                            <Cell
                                                style={{flex: 3}}><b>{moment(item?.created_at).format('DD MMMM YYYY ddd, HH:mm')}</b></Cell>
                                            <Cell style={{flex: 10}}>{item?.description}</Cell>
                                            <Cell
                                                style={{
                                                    flex: 3,
                                                    alignItems: 'end',
                                                    paddingRight: 0,
                                                    color: item?.type === 1 ? '#E05252' : '#37B358'
                                                }}><b>{item?.type === 1 ? '-' : '+'}{item?.price?.toFixed(2)} ₺</b></Cell>
                                        </Row>
                                    ))}
                                </Table>
                            </div>
                        </>


                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default PaymentRemittances;
